import React, { useContext, useEffect, useRef, useState } from 'react';
import * as styles from './Hierarchy.module.scss';
import * as general from '../Styles.module.scss';
import { OrganisationContext, UserContext } from '../../../Context/UserContext';
import { Department, Employee, Team } from '../../../Classes/Employee';
import { StatusColours } from '../../../Enums/EmploymentStatus';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnassignedTitle from '../../../components/UnassignedTitle/UnassignedTitle';

const EmployeePortalHierarchy: React.FC = () => {
  const { Language } = useContext(UserContext);
  const { Organisation } = useContext(OrganisationContext);

  return (<>
    <div className={general.Main}>
      <div className={styles.Hierarchy}>
        <div className={styles.HierarchyInner}>
          <h1>{Language.Applications.CompanyHierarchy}</h1>
          <p>A {Language.Applications.CompanyHierarchy} is the structure of a company. This can be useful when Employees are wondering who to report to when they require leave or who to ask when they have questions.</p>
          <hr />

          <div className={styles.Content}>
            {Organisation.Employees.filter(e => !StatusColours.find(s => e.Status === s.Status)?.isDismissed && (!e.Business.Department || !e.Business.Department.ID)).length ? <div className={styles.ContentInner}>
              <div className={styles.Department}>
                <h2>
                  <span>Unassigned</span>
                </h2>
                <div className={styles.EmployeesRow}>
                  {
                    Organisation.Employees.filter(e => !StatusColours.find(s => e.Status === s.Status)?.isDismissed && (!e.Business.Department || !e.Business.Department.ID)).map((e) => {
                      return <EmployeeProfile Employee={e} />
                    })
                  }
                </div>
              </div>
            </div>
              : <></>}

            <div className={styles.ContentInner}>
              {Organisation.Departments.filter(d => !d.Parent).map(d => {
                return <DepartmentBlock Employees={Organisation.Employees} Teams={Organisation.Teams} Departments={Organisation.Departments} Department={d} />
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
};

type EmployeeProps = {
  Employee: Employee;
}

const EmployeeProfile: React.FC<EmployeeProps> = ({ Employee }) => {
  return <>
    <div className={`${styles.Employee}`}>
      <button type='button' title={`${Employee.Personal.Forename} ${Employee.Personal.Surname}`}>
        <span>{Employee.ID ? `${Employee.Personal.Forename.split("")[0]}${Employee.Personal.Surname.split("")[0]}` : ""}</span>
        <small>
          <b>{Employee.Personal.Forename} {Employee.Personal.Surname}</b>
          {Employee.Business.CurrentEmployment.JobTitle ? Employee.Business.CurrentEmployment.JobTitle : <UnassignedTitle />}
        </small>
      </button>
    </div>
  </>
}

interface DepartmentBlock {
  Department?: Department;
  Departments: Department[];
  Teams: Team[];
  Employees: Employee[];
}

export const DepartmentBlock: React.FC<DepartmentBlock> = ({ Department, Departments, Teams, Employees }) => {
  const [toggleSection, setToggleSection] = useState<boolean>(true);

  return <div className={styles.Department}>
    <h2>
      <span>
        {Department?.Title}
      </span>
      <button type="button" title='Hide Employees' onClick={() => setToggleSection(!toggleSection)}>
        <i>
          <FontAwesomeIcon icon={!toggleSection ? faEye : faEyeSlash} />
        </i>
      </button>
    </h2>

    {Employees.find(e => !StatusColours.find(s => e.Status === s.Status)?.isDismissed && e.ID === Department?.Manager.ID) ? <div className={styles.Manager}>
      <EmployeeProfile Employee={Employees.find(e => e.ID === Department?.Manager.ID)} />
    </div> : <></>}

    {toggleSection ? <>
      <div className={styles.EmployeesRow}>
        {
          Employees.filter(e => !StatusColours.find(s => e.Status === s.Status)?.isDismissed && e.ID !== Department?.Manager.ID && e.Business.Department && e.Business.Department.ID === Department?.ID && (!e.Business.Team || !e.Business.Team.ID)).map((e) => {
            return <EmployeeProfile Employee={e} />
          })
        }
      </div>
    </> : <></>}
    <div className={styles.TeamsRow}>
      {
        Teams.filter(t => t.DepartmentID === Department?.ID).map((t) => {
          return <TeamBlock Teams={Teams} Employees={Employees} Team={t} Departments={Departments} />
        })
      }
    </div>
    <div className={styles.DepartmentsRow}>
      {
        Departments.filter(d => d.Parent === Department?.ID).map((d) => {
          return <DepartmentBlock Employees={Employees} Departments={Departments} Department={d} Teams={Teams} />
        })
      }
    </div>
  </div>
}

interface TeamBlock {
  Team: Team;
  Employees: Employee[];
  Departments: Department[];
  Teams: Team[];
}

export const TeamBlock: React.FC<TeamBlock> = ({ Team, Employees }) => {
  const [toggleSection, setToggleSection] = useState<boolean>(true);

  return <div className={styles.Team}>
    <h3>
      <span>
        {Team.Title}
      </span>
      <button type="button" title='Hide Employees' onClick={() => setToggleSection(!toggleSection)}>
        <i>
          <FontAwesomeIcon icon={!toggleSection ? faEye : faEyeSlash} />
        </i>
      </button>
    </h3>
    {Employees.find(e => !StatusColours.find(s => e.Status === s.Status)?.isDismissed && e.ID === Team?.Leader.ID) ? <div className={styles.Manager}>
      <EmployeeProfile Employee={Employees.find(e => e.ID === Team?.Leader.ID)} />
    </div> : <></>}
    {toggleSection ? <>
      <div className={styles.EmployeesRow}>
        {
          Employees.filter(e => !StatusColours.find(s => e.Status === s.Status)?.isDismissed && e.ID !== Team?.Leader.ID && (e.Business.Team && e.Business.Team.ID === Team.ID)).map((e) => {
            return <EmployeeProfile Employee={e} />
          })
        }
      </div>
    </> : <></>}
  </div>
}
export default EmployeePortalHierarchy;
