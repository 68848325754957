import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Policy-Library.module.scss';
import * as general from '../Styles.module.scss';
import { Link } from 'gatsby';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { EmployeeContext, UserContext } from '../../../Context/UserContext';
import { Policy } from '../../../Classes/Document';
import { Fetch } from '../../../Services/Fetch';
import { API_GetPolicies } from '../../../Services/ApiRoutes';
import { Loading } from '../../../components/Loading/Loading';
import { EncryptValue } from '../../../Services/Crypto';
import { faCheckDouble, faSignature } from '@fortawesome/free-solid-svg-icons';

const EmployeePolicyLibrary: React.FC = () => {
  const { User } = useContext(UserContext);
  const { Employee } = useContext(EmployeeContext);
  const [documents, setDocuments] = useState<Policy[]>([] as Policy[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [signed, setSigned] = useState<boolean>(false);

  useEffect(() => {
    if (Employee.ID)
      GetData();
  }, [Employee])

  const GetData = async () => {
    setLoading(true);

    await Fetch(`${API_GetPolicies}`, { UserID: Employee.Account.CompanyID, CustomerID: User.isConsultant && User.isEmployee ? User.PackageID : 0 }).then((Response: Policy[]) => {
      setDocuments(Response);
    });

    setLoading(false);
  }

  return (<div className={styles.Body}>
    {loading ? <div className={styles.Loading}><Loading /></div> : <>
      <div className={general.Main}>
        <div className={general.MainInner}>
          <div className={styles.Tabs}>
            <button className={!signed ? styles.Active : ""} onClick={() => setSigned(false)}>
              Not Signed ({documents.filter(d => (!d.Signed || !d.Signed.ID)).length})
            </button>
            <button className={signed ? styles.Active : ""} onClick={() => setSigned(true)}>
              Signed ({documents.filter(d => (d.Signed && d.Signed.ID)).length})
            </button>
          </div>

          <div className={styles.Table}>
            {
              documents.filter(d => (signed && d.Signed && d.Signed.ID) || (!signed && (!d.Signed || !d.Signed.ID))).length ? documents.filter(d => (signed && d.Signed && d.Signed.ID) || (!signed && (!d.Signed || !d.Signed.ID))).map(d => {
                return <div className={styles.TableRow}>
                  <div className={`${styles.Document}`}>
                    <div className={`${styles.DocumentInner}`}>
                      <i className={signed ? styles.Green : ""}>
                        <FontAwesomeIcon icon={signed ? faCheckDouble : faSignature} />
                      </i>

                      <Link to={`/Employee/Portal/Policies/Policy/${EncryptValue(d.ID.toString())}`}>
                        <b>{d.Title}</b>
                        {signed ? <span>Signed {new Date(d.Signed.SignedOn).toLocaleString()}</span> : <></>}
                      </Link>
                    </div>
                  </div>
                </div>
              }) : <div className={general.Message}>
                <i>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </i>
                <h1>{signed ? "Nothing Signed" : "Everything Signed"}</h1>
                <p>{signed ? "You have not yet signed any of the policies of the company" : "All of the comapny policies have been reviewed and signed by you"}</p>
              </div>
            }
          </div>
        </div>
      </div>
    </>}
  </div>);
};

export default EmployeePolicyLibrary;
