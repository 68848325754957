import React, { useContext } from 'react';
import * as styles from './Calendar.module.scss';
import * as general from '../Styles.module.scss';
import { EmployeeContext } from '../../../Context/UserContext';
import { Loading } from '../../../components/Loading/Loading';
import PortalEmployeeCalendar from '../../Portal/Employees/Employee/Calendar/Calendar';

const EmployeePortalCalendar: React.FC = () => {
  const { Employee, setEmployee } = useContext(EmployeeContext);

  return (<>
    {!Employee.ID ? <div className={general.Loading}><Loading /></div> : <>
      <div className={general.Main}>
        <div className={general.MainInner}>
          {Employee && Employee.Business ? <div className={styles.Calendar}>
            <PortalEmployeeCalendar CustomerID="N/A" employee={Employee} setEmployee={(employ) => { setEmployee(employ) }} />
          </div> : <></>}
        </div>
      </div>
    </>}
  </>);
};

export default EmployeePortalCalendar;
