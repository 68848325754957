import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Home.module.scss';
import * as general from '../Styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faBug, faCalendar, faCalendarAlt, faCalendarCheck, faClipboardList, faPhone, faSave, faSignature, faSitemap, faSpinner, faThList, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { EmployeeContext, OrganisationContext, UserContext } from '../../../Context/UserContext';
import Input from '../../../components/Input/input';
import { Loading } from '../../../components/Loading/Loading';
import { API_EmployeeChangePassword, API_GetPolicies } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Encrypt } from '../../../Services/Crypto';
import { Policy } from '../../../Classes/Document';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import { EmploymentStatus, StatusColour, StatusColours } from '../../../Enums/EmploymentStatus';
import { Objective, Employee, Department, CalendarEvent, LieuDay, CarryOver, Team } from '../../../Classes/Employee';

const EmployeePortalHome: React.FC = () => {
  const { User, Language } = useContext(UserContext);
  const { Employee, setEmployee } = useContext(EmployeeContext);
  const { Organisation } = useContext(OrganisationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [policies, setPolicies] = useState<Policy[]>([] as Policy[]);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordStength, setPasswordStength] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [savePassword, setSavePassword] = useState<boolean>(false);

  useEffect(() => {
    if (Employee.ID && !Employee.Account.hasTempPassword)
      GetPolicies();
  }, [Employee]);

  useEffect(() => {
    setMessage("");
  }, [password, confirmPassword])

  const GetPolicies = async () => {
    await Fetch(`${API_GetPolicies}`, { UserID: Employee.Account.CompanyID, CustomerID: 0 }).then((Response: Policy[]) => {
      setPolicies(Response);
    });
    setLoading(false);
  }

  const ChangePassword = (e: any) => {
    e.preventDefault();
    let mess: string = "";
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    setSavePassword(true);

    if (!format.test(password)) {
      mess = "Your Password does not contain any special characters or symbols. ";
    }

    if (password.length < 8) {
      mess += "Your Password length is not long enough. ";
    }

    if (!(/[A-Z]/.test(password)))
      mess += "Your Password does not contain any capital letters. ";

    if (!(/[0-9]/.test(password)))
      mess += "Your Password does not contain any numbers. ";

    if (passwordStength < 3)
      mess += "Your Password is not Strong or Secure Enough.";

    if (!mess)
      if (password === confirmPassword) {
        Fetch(API_EmployeeChangePassword, { ...User, password: Encrypt(password) }).then((Success: boolean) => {
          setMessage("");
          if (Success) {
            setEmployee({ ...Employee, Account: { ...Employee.Account, hasTempPassword: false } });
            setPassword("");
            setConfirmPassword("")
          } else
            setMessage("Something went wrong saving your password");
          setSavePassword(false);
        })
      } else {
        setMessage("Your Passwords do not match");
        setSavePassword(false);
      }
    else {
      setSavePassword(false);
      setMessage(mess);
    }
  }

  const GetEmployeeVacations = () => {
    let count: number = 0;
    Organisation.Employees.filter(e => e.ID !== User.ID && (User.isEmployee ? (e.Business.Department.Manager && e.Business.Department.Manager.ID === User.ID) || (e.Business.Team.Leader && e.Business.Team.Leader.ID === User.ID) : true) && e.CalendarEvents.find(c => StatusColours.find(s => s.Status === c.Type)?.canEmployee && !c.isApproved)).map(e => {
      count += e.CalendarEvents.filter(c => StatusColours.find(s => s.Status === c.Type)?.canEmployee && !c.isApproved).length;
    })
    return count;
  }

  const GetTasks = () => {
    return Organisation.Objectives.filter((objective: Objective) => User.TaskStatusOptionsData[User.TaskStatusOptionsData.length - 1].Name === objective.Status && objective.EmployeeID === User.ID).length
  }

  const GetOpenRequests = () => {
    let emp: Employee[] = [] as Employee[];
    let deps: Department[] = Organisation.Departments.filter(t => t.Manager.ID === User.ID);
    deps.map((dep: Department) => {
      const depEmps: Employee[] = GetEmployees(dep);

      depEmps.filter((employee: Employee) => !emp.find((emp: Employee) => emp.ID === employee.ID)).map((employee: Employee) => {
        if (employee.CalendarEvents.find((event: CalendarEvent) => StatusColours.find((s: StatusColour) => event.Type === s.Status)?.canEmployee))
          emp.push(employee);
      })

      emp = getSubDepartments(dep.ID, emp, 0);
    });

    return emp;
  }

  const getSubDepartments = (DepartmentID: number, Data: Employee[], Status: number) => {
    Organisation.Departments.filter((Department: Department) => Department.Parent === DepartmentID).map((Department: Department) => {
      const emps: Employee[] = GetEmployees(Department);

      emps.filter((employee: Employee) => !Data.find((emp: Employee) => emp.ID === employee.ID)).map((employee: Employee) => {
        if (employee.CalendarEvents.find((event: CalendarEvent) => (Status ? event.isApproved === Status : true)))
          Data.push(employee);
      })

      if (Organisation.Departments.find((dep: Department) => dep.Parent === Department.ID))
        Data = getSubDepartments(Department.ID, Data, Status);
    });

    return Data;
  }

  const GetEmployees = (Department: Department) => {
    return Organisation.Employees.filter((e: Employee) => User.ID !== e.ID && e.Business.Department.ID === Department.ID);
  }

  const getInLieuTime = () => {
    let count: number = 0;

    JSON.parse(Employee.Business.HolidayEntitlement.LieuDays ? Employee.Business.HolidayEntitlement.LieuDays : "[]").map((lieu: LieuDay) => {
      if (new Date(lieu.Date).getFullYear() === new Date().getFullYear())
        count += parseInt(lieu.Time);
    });

    return count;
  }

  const getUsedInLieuTime = (Status: number) => {
    var count = 0;
    let annualLeave: string = Object.keys(EmploymentStatus)[Object.values(EmploymentStatus).findIndex(e => e === EmploymentStatus.TIME_IN_LIEU)];
    Employee.CalendarEvents.filter(e => e.isApproved === Status && e.SettingsJSON && e.SettingsJSON.filter(s => new Date(s.Day).getFullYear() === new Date().getFullYear()).length && e.Type === annualLeave).map(e => {
      e.SettingsJSON.filter(s => new Date(s.Day).getFullYear() === new Date().getFullYear()).map(s => {
        if (!Organisation.BankHolidays.find(b => b.Days.find(x => +new Date(x.date) === +new Date(s.Day))))
          count -= Employee.Business.HolidayEntitlement.TimeOffUnit === "Days" ? (s.isHalfDay ? .5 : 1) : (s.Hours ? s.Hours : 0);
        else
          if (Employee.Business.CurrentEmployment.worksBankHolidays)
            count -= Employee.Business.HolidayEntitlement.TimeOffUnit === "Days" ? (s.isHalfDay ? .5 : 1) : (s.Hours ? s.Hours : 0);
      })
    })
    return count;
  }

  const getCarryOverDays = () => {
    const carryOverData: CarryOver[] = JSON.parse(Employee.Business.HolidayEntitlement.CarryOver ? Employee.Business.HolidayEntitlement.CarryOver : '[]');
    const currentCarryOver: CarryOver | undefined = carryOverData.find((co: CarryOver) => parseInt(co.Year) === new Date().getFullYear());
    return currentCarryOver ? parseFloat(currentCarryOver.Time) : 0;
  }

  const getTotalDays = () => {
    return parseInt(Employee.Business.HolidayEntitlement.StatutoryDays ? Employee.Business.HolidayEntitlement.StatutoryDays : "0") + getCarryOverDays() + parseInt(Employee.Business.HolidayEntitlement.ExtraDays ? Employee.Business.HolidayEntitlement.ExtraDays : "0");
  }

  const GetUsedCount = (Status: number) => {
    var count = 0;
    let annualLeave: string = Object.keys(EmploymentStatus)[Object.values(EmploymentStatus).findIndex(e => e === EmploymentStatus.ANNUAL_LEAVE)];
    Employee.CalendarEvents.filter(e => e.isApproved === Status && e.SettingsJSON && e.SettingsJSON.filter(s => new Date(s.Day).getFullYear() === new Date().getFullYear()).length && e.Type === annualLeave).map(e => {
      e.SettingsJSON.filter(s => new Date(s.Day).getFullYear() === new Date().getFullYear()).map(s => {
        if (!Organisation.BankHolidays.find(b => b.Days.find(x => +new Date(x.date) === +new Date(s.Day))))
          count -= Employee.Business.HolidayEntitlement.TimeOffUnit === "Days" ? (s.isHalfDay ? .5 : 1) : (s.Hours ? s.Hours : 0);
        else
          if (Employee.Business.CurrentEmployment.worksBankHolidays)
            count -= Employee.Business.HolidayEntitlement.TimeOffUnit === "Days" ? (s.isHalfDay ? .5 : 1) : (s.Hours ? s.Hours : 0);
      })
    })
    return count;
  }

  const isManager = () => {
    return Organisation.Departments.find((dep: Department) => dep.Manager.ID === Employee.ID);
  }

  const isLeader = () => {
    return Organisation.Teams.find((team: Team) => team.Leader.ID === Employee.ID);
  }

  return (<>
    <div className={`${general.Modal} ${Employee.ID && Employee.Account.hasTempPassword ? general.Show : ""}`}>
      <div className={`${general.ModalBackground} ${general.Blur}`}></div>
      <div className={general.ModalContent}>
        <form className={`${general.ModalBody}`} onSubmit={(e) => ChangePassword(e)}>
          <h1>Change Password</h1>
          <p>You are currently set up to have a Temporary Password. Please change this before continuing.</p>
          <Input Type="password" Label="Password" Value={password} OnChange={(v) => setPassword(v)} Placeholder="..." />
          <div className={styles.PasswordStength}>
            <PasswordStrengthBar password={password} className={styles.PasswordStengthBar} onChangeScore={(e) => setPasswordStength(e)} />
            <p>Our Passwords Require:</p>
            <ul>
              <li>Minimum of 1 Capital Letter</li>
              <li>Minimum of 1 Symbol or Special Character (!,$,£,@, etc)</li>
              <li>Minimum of 8 Characters</li>
              <li>Minimum of 1 Number</li>
              <li>Password Strength should be either "Good" or "Strong"</li>
            </ul>
          </div>
          <Input Type="password" Label="Confirm Password" Value={confirmPassword} OnChange={(v) => setConfirmPassword(v)} Placeholder="..." />

          {message ? <div className={styles.Error}>
            <p>{message}</p>
          </div> : <></>}

          <div className={styles.Buttons}>
            <button disabled={message !== ""} className={general.Button} type="submit">
              <i>
                <FontAwesomeIcon icon={savePassword ? faSpinner : faSave} spin={savePassword} />
              </i>
              Save
            </button>
          </div>
        </form>
      </div>
    </div>

    {loading || !Employee.ID ? <div className={general.Loading}><Loading /></div> : <>
      <div className={general.Main}>
        <div className={styles.Apps}>
          <div className={styles.Apps}>
            <div className={styles.AppsBlock}>
              <Link to="/Employee/Portal/Calendar" className={styles.App} title={"Request Leave"}>
                <i>
                  <FontAwesomeIcon icon={faCalendar} />
                </i>
                <p>Request Leave</p>
              </Link>
              <Link to="/Employee/Portal/Account/Contacts" className={styles.App} title={"Add Emergency Contact"}>
                <i>
                  <FontAwesomeIcon icon={faPhone} />
                </i>
                <p>Add Contact</p>
              </Link>
              <Link to="/Employee/Portal/PeerReview" className={styles.App} title={"Submit Peer Review"}>
                <i>
                  <FontAwesomeIcon icon={faClipboardList} />
                </i>
                <p>Submit Peer Review</p>
              </Link>
              <Link to="/Employee/Portal/Account/Leave Requests" className={styles.App} title={"Review Requests"}>
                <i>
                  <FontAwesomeIcon icon={faCalendarCheck} />
                </i>
                <p>Review Requests</p>
              </Link>
            </div>
            <div className={styles.AppsBlock}>
              <Link to="/Employee/Portal/Hierarchy" className={styles.App} title={Language.Applications.CompanyHierarchy}>
                <i>
                  <FontAwesomeIcon icon={faSitemap} />
                </i>
                <p>{Language.Applications.CompanyHierarchy}</p>
              </Link>
              <Link to="/Employee/Portal/Company Calendar" title={Language.Applications.CompanyCalendar} className={styles.App}>
                <i>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </i>
                <p>{Language.Applications.CompanyCalendar}</p>
              </Link>
              <Link to="/Employee/Portal/Directory" title={Language.Applications.Directory} className={styles.App}>
                <i>
                  <FontAwesomeIcon icon={faAddressBook} />
                </i>
                <p>{Language.Applications.Directory}</p>
              </Link>
              <Link to="/Employee/Portal/Policies" title={Language.Application_Employees.Policies} className={styles.App}>
                <i>
                  <FontAwesomeIcon icon={faSignature} />
                </i>
                <p>{Language.Application_Employees.Policies}</p>
              </Link>
            </div>
            <div className={styles.AppsBlock}>
              {isManager() ? <Link to="/Employee/Portal/Departments" className={styles.App} title={Language.Applications.Departments}>
                <i>
                  <FontAwesomeIcon icon={faThList} />
                </i>
                <p>My {Language.Applications.Departments}</p>
              </Link> : <></>}
              {isLeader() ? <Link to="/Employee/Portal/Employees/Teams" className={styles.App} title={Language.Applications.Teams}>
                <i>
                  <FontAwesomeIcon icon={faUsers} />
                </i>
                <p>My {Language.Applications.Teams}</p>
              </Link> : <></>}
              {isLeader() || isManager() ? <Link to="/Employee/Portal/Leave Requests" className={styles.App} title={Language.Applications.LeaveRequests}>
                <i>
                  <FontAwesomeIcon icon={faCalendarCheck} />
                </i>
                <p>{Language.Applications.LeaveRequests}</p>
              </Link> : <></>}
              <a target="_blank" href="https://primehr.atlassian.net/servicedesk/customer/portal/1" className={styles.App} title={"Report Issue"}>
                <i>
                  <FontAwesomeIcon icon={faBug} />
                </i>
                <p>Report Issue</p>
              </a>
            </div>
          </div>
        </div>

        {(policies.filter(p => !p.Signed.ID).length || !Organisation.Employees.find((e: Employee) => e.PeerReviews.find(p => new Date(p.Date).getMonth() === new Date().getMonth() && new Date(p.Date).getFullYear() === new Date().getFullYear())) || GetEmployeeVacations() || GetTasks() || GetOpenRequests().length) ? <div className={styles.Alerts}>
          <div className={styles.AlertsInner}>
            {
              policies.filter(p => !p.Signed.ID).length ? <Link to={`/Employee/Portal/Policies`} className={styles.Alert}>
                <p>
                  You have policies that require a signature
                  <small>These policies are required to be signed by every employee to ensure company wide understand, click <i>HERE</i> to sign them now</small>
                </p>
                <i>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </i>
              </Link> : <></>
            }

            {
              Organisation.Employees.find((e: Employee) => e.PeerReviews.find(p => new Date(p.Date).getMonth() === new Date().getMonth() && new Date(p.Date).getFullYear() === new Date().getFullYear())) ? <></> : <Link to={`/Employee/Portal/PeerReview`} className={styles.Alert}>
                <p>
                  You have not submitted any Peer Reviews this month
                  <small>We like to ask that each month you complete a Peer Review. This will help us to help everyone when they need it.</small>
                </p>
                <i>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </i>
              </Link>
            }

            {
              GetEmployeeVacations() ? <Link to={`/Employee/Portal/Leave Requests`} className={styles.Alert}>
                <p>
                  Leave Requires Approval
                  <small>Employees have requested leave and require your approval</small>
                </p>
                <i>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </i>
              </Link> : <></>
            }

            {
              GetTasks() ? <Link to={`/Employee/Portal/Account/Task Manager`} className={styles.Alert}>
                <p>
                  Tasks To Be Completed
                  <small>You have tasks that still need to be completed</small>
                </p>
                <i>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </i>
              </Link> : <></>
            }

            {
              GetOpenRequests().length ? <Link to={`/Employee/Portal/Leave Requests`} className={styles.Alert}>
                <p>
                  Leave has been Requested
                  <small>Employees within your hierarchy have requested leave that requires approval from a department manager</small>
                </p>
                <i>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </i>
              </Link> : <></>
            }
          </div>
        </div> : <></>}
      </div>
    </>}
  </>);
};

export default EmployeePortalHome;
