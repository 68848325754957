// extracted by mini-css-extract-plugin
export var Alert = "Calendar-module--Alert--d8a51";
export var Alerts = "Calendar-module--Alerts--f2cde";
export var BANKHOLIDAY = "Calendar-module--BANKHOLIDAY--1e0b5";
export var Birthday = "Calendar-module--Birthday--f1034";
export var Buttons = "Calendar-module--Buttons--343fe";
export var Calendar = "Calendar-module--Calendar--76275";
export var Error = "Calendar-module--Error--890e5";
export var Event = "Calendar-module--Event--63ed9";
export var Events = "Calendar-module--Events--22f60";
export var EventsBlock = "Calendar-module--EventsBlock--602a4";
export var Form = "Calendar-module--Form--46aa9";
export var FormHeader = "Calendar-module--FormHeader--46d78";
export var FormInner = "Calendar-module--FormInner--c4e4a";
export var Loading = "Calendar-module--Loading--dfcf8";
export var Message = "Calendar-module--Message--eb301";
export var PasswordStength = "Calendar-module--PasswordStength--18d0a";
export var Show = "Calendar-module--Show--3e83e";