import React, { useContext, useEffect } from 'react';
import PortalNav from '../../../components/PortalNav/PortalNav';
import { Router } from "@reach/router"
import { UserContext } from '../../../Context/UserContext';
import { navigate } from 'gatsby';
import EmployeePortalAccount from '../../../Views/EmployeePortal/Account/Account';
import EmployeePortalAccountCorporate from '../../../Views/EmployeePortal/Account/Corporate';
import EmployeePortalHome from '../../../Views/EmployeePortal/Home/Home';
import EmployeePortalHierarchy from '../../../Views/EmployeePortal/Hierarchy/Hierarchy';
import EmployeePortalContacts from '../../../Views/EmployeePortal/Account/Contacts';
import EmployeePortalWorklog from '../../../Views/EmployeePortal/Account/Worklog/Worklog';
import EmployeePortalTeam from '../../../Views/EmployeePortal/Team/Team';
import EmployeePortalEmployee from '../../../Views/EmployeePortal/Employee/Employee';
import EmployeePortalDepartment from '../../../Views/EmployeePortal/Department/Department';
import EmployeePolicyLibrary from '../../../Views/EmployeePortal/Policy-Library/Policy-Library';
import EmployeePolicy from '../../../Views/EmployeePortal/Policy-Library/Policy/Policy';
import EmployeePortalCompanyCalendar from '../../../Views/EmployeePortal/CompanyCalendar/CompanyCalendar';
import EmployeeHolidayRequests from '../../../Views/EmployeePortal/Holiday-Requests/Holiday-Requests';
import EmployeeAccountHolidays from '../../../Views/EmployeePortal/Account/HolidayRequests/HolidayRequests';
import PortalDirectory from '../../../Views/EmployeePortal/Directory/Directory';
import EmployeePortalFeedback from '../../../Views/EmployeePortal/Feedback/Feedback';
import EmployeePortalPeerReview from '../../../Views/EmployeePortal/PeerReview/PeerReview';
import EmployeePortalTask from '../../../Views/EmployeePortal/Account/Tasks';
import EmployeePortalHappiness from '../../../Views/EmployeePortal/Account/Happiness';
import EmployeePortalAccountBenefits from '../../../Views/EmployeePortal/Account/Benefits';
import EmployeePortalAssets from '../../../Views/EmployeePortal/Account/Assets';
import PortalLayout from '../../../components/Layout/PortalLayout';
import Courses from '../../../Views/Portal/Courses/Courses/Table/Courses';
import Modules from '../../../Views/Portal/Courses/Modules/Table/Modules';
import Lessons from '../../../Views/Portal/Courses/Lessons/Table/Lessons';
import Lesson from '../../../Views/Portal/Courses/Lessons/Lesson/Lesson';
import EmployeePortalCalendar from '../../../Views/EmployeePortal/Calendar/Calendar';
import ReleaseNotes from '../../../Views/Portal/ReleaseNotes/ReleaseNotes';

const EmployeePortalPage: React.FC = () => {
  const { User } = useContext(UserContext);

  useEffect(() => {
    if (User.ID) {
      if (User.isAdmin)
        navigate("/Admin/");

      if (!User.isEmployee)
        navigate("/Portal/");
    }
  }, [User])

  return (<PortalLayout>
    <PortalNav>
      <Router basepath="/Employee/Portal" className={"router"}>
        <PrivateRoute path="/" component={EmployeePortalHome} />
        <PrivateRoute path="/Account" component={EmployeePortalAccount} />
        <PrivateRoute path="/Account/Corporate" component={EmployeePortalAccountCorporate} />
        <PrivateRoute path="/Account/Contacts" component={EmployeePortalContacts} />
        <PrivateRoute path="/Account/Worklog" component={EmployeePortalWorklog} />
        <PrivateRoute path="/Account/Leave%20Requests/:RefID" component={EmployeeAccountHolidays} />
        <PrivateRoute path="/Account/Leave%20Requests" component={EmployeeAccountHolidays} />
        <PrivateRoute path="/Account/Task%20Manager" component={EmployeePortalTask} />
        <PrivateRoute path="/Account/Benefits" component={EmployeePortalAccountBenefits} />
        <PrivateRoute path="/Account/Assigned%20Assets" component={EmployeePortalAssets} />
        <PrivateRoute path="/Account/Happiness" component={EmployeePortalHappiness} />
        <PrivateRoute path="/Calendar" component={EmployeePortalCalendar} />
        <PrivateRoute path="/Hierarchy" component={EmployeePortalHierarchy} />
        <PrivateRoute path="/Teams/:RefID" component={EmployeePortalTeam} />
        <PrivateRoute path="/Teams" component={EmployeePortalTeam} />
        <PrivateRoute path="/Departments/:RefID" component={EmployeePortalDepartment} />
        <PrivateRoute path="/Departments" component={EmployeePortalDepartment} />
        <PrivateRoute path="/Policies" component={EmployeePolicyLibrary} />
        <PrivateRoute path="/Directory" component={PortalDirectory} />
        <PrivateRoute path="/Company%20Calendar" component={EmployeePortalCompanyCalendar} />
        <PrivateRoute path="/Leave%20Requests/:RefID" component={EmployeeHolidayRequests} />
        <PrivateRoute path="/Leave%20Requests" component={EmployeeHolidayRequests} />
        <PrivateRoute path="/Policies/Policy/:ID" component={EmployeePolicy} />
        <PrivateRoute path="/Employees/Employee/:ID/:Tab/:RefID" component={EmployeePortalEmployee} />
        <PrivateRoute path="/Employees/Employee/:ID/:Tab" component={EmployeePortalEmployee} />
        <PrivateRoute path="/Employees/Employee/:ID" component={EmployeePortalEmployee} />
        <PrivateRoute path="/Feedback" component={EmployeePortalFeedback} />
        <PrivateRoute path="/PeerReview" component={EmployeePortalPeerReview} />
        <PrivateRoute path={"/Courses"} component={Courses} />
        <PrivateRoute path={"/Courses/Modules/:CourseID"} component={Modules} />
        <PrivateRoute path={"/Courses/Modules/Lessons/:ModuleID"} component={Lessons} />
        <PrivateRoute path={"/Courses/Modules/Lesson/:LessonID/Slide/:SlideID"} component={Lesson} />
        <PrivateRoute path="/ReleaseNotes" component={ReleaseNotes} />
      </Router>
    </PortalNav>
  </PortalLayout>);
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Component {...rest} />
}

export default EmployeePortalPage;
