import React, { useContext } from 'react';
import * as general from '../Styles.module.scss';
import * as other from './HolidayRequests/HolidayRequests.module.scss';
import { EmployeeContext } from '../../../Context/UserContext';
import { Loading } from '../../../components/Loading/Loading';
import PortalTaskManager from '../../Portal/Employees/TaskManager/TaskManager';
import { Encrypt } from '../../../Services/Crypto';
import { EmployeeAccountTabs } from './Account';

const EmployeePortalTask: React.FC = () => {
  const { Employee } = useContext(EmployeeContext);

  return (<div className={general.Main}>
    {Employee.ID ? <div className={general.Content}>
      <EmployeeAccountTabs {...Employee} />

      <div className={other.ContentInner}>
        <PortalTaskManager EmployeeView={true} ID={Employee.ID} Type={'Employee'} CustomerID={encodeURIComponent(Encrypt(Employee.CustomerID.toString()))} />
      </div>
    </div> : <div className={general.Loading}><Loading /></div>}
  </div>);
};

export default EmployeePortalTask;
