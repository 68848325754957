// extracted by mini-css-extract-plugin
export var Active = "Department-module--Active--687d6";
export var BANKHOLIDAY = "Department-module--BANKHOLIDAY--51b4d";
export var BIRTHDAY = "Department-module--BIRTHDAY--ead08";
export var Back = "Department-module--Back--f692f";
export var BankHolidays = "Department-module--BankHolidays--5ede6";
export var Department = "Department-module--Department--5e7e1";
export var Employee = "Department-module--Employee--a3b66";
export var EmployeeImage = "Department-module--EmployeeImage--a8646";
export var EmployeeInfo = "Department-module--EmployeeInfo--802b9";
export var EmployeeLinks = "Department-module--EmployeeLinks--e1ec4";
export var EmployeeStatus = "Department-module--EmployeeStatus--2a791";
export var Event = "Department-module--Event--52478";
export var Events = "Department-module--Events--7434f";
export var EventsBlock = "Department-module--EventsBlock--c8be1";
export var Filter = "Department-module--Filter--8eda6";
export var Full = "Department-module--Full--4f348";
export var Half = "Department-module--Half--c8883";
export var Header = "Department-module--Header--cc7cf";
export var Main = "Department-module--Main--7d614";
export var MainInner = "Department-module--MainInner--ebcf7";
export var MainOuter = "Department-module--MainOuter--898da";
export var Message = "Department-module--Message--48ec4";
export var Show = "Department-module--Show--5d951";
export var Side = "Department-module--Side--ebea7";
export var Stats = "Department-module--Stats--63c9a";
export var StatsItem = "Department-module--StatsItem--1a7d3";
export var Tabs = "Department-module--Tabs--50159";
export var TaskInner = "Department-module--TaskInner--573ed";
export var Tasklist = "Department-module--Tasklist--0bb94";
export var Tasks = "Department-module--Tasks--94555";
export var Unselected = "Department-module--Unselected--29d9f";
export var Updates = "Department-module--Updates--bd17d";
export var UpdatesBlock = "Department-module--UpdatesBlock--13ab0";
export var UpdatesInner = "Department-module--UpdatesInner--5b257";
export var UpdatesItem = "Department-module--UpdatesItem--83c5e";
export var UpdatesItemInner = "Department-module--UpdatesItemInner--8334d";