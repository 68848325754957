// extracted by mini-css-extract-plugin
export var Add = "CompanyCalendar-module--Add--50e0a";
export var Blank = "CompanyCalendar-module--Blank--d2894";
export var Calendar = "CompanyCalendar-module--Calendar--9e58f";
export var CalendarDay = "CompanyCalendar-module--CalendarDay--227bd";
export var CalendarHeader = "CompanyCalendar-module--CalendarHeader--de11e";
export var CalendarInner = "CompanyCalendar-module--CalendarInner--94692";
export var CalendarRow = "CompanyCalendar-module--CalendarRow--3ba2b";
export var Content = "CompanyCalendar-module--Content--498b5";
export var ContentInner = "CompanyCalendar-module--ContentInner--2d640";
export var Employee = "CompanyCalendar-module--Employee--6eac2";
export var EmployeeEvent = "CompanyCalendar-module--EmployeeEvent--92db7";
export var Filter = "CompanyCalendar-module--Filter--a5c83";
export var FilterInner = "CompanyCalendar-module--FilterInner--0d5ac";
export var Form = "CompanyCalendar-module--Form--d4baa";
export var Header = "CompanyCalendar-module--Header--19872";
export var HeaderFilter = "CompanyCalendar-module--HeaderFilter--3ae59";
export var HeaderInner = "CompanyCalendar-module--HeaderInner--ede3c";
export var Input = "CompanyCalendar-module--Input--13006";
export var Key = "CompanyCalendar-module--Key--de18b";
export var Message = "CompanyCalendar-module--Message--929ac";
export var Show = "CompanyCalendar-module--Show--fd17e";
export var Today = "CompanyCalendar-module--Today--2600e";
export var Unapproved = "CompanyCalendar-module--Unapproved--43ba8";
export var Weekend = "CompanyCalendar-module--Weekend--32a55";
export var half = "CompanyCalendar-module--half--55a27";
export var quarter = "CompanyCalendar-module--quarter--cd035";
export var third = "CompanyCalendar-module--third--de6d3";