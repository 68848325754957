// extracted by mini-css-extract-plugin
export var Content = "Hierarchy-module--Content--f84d8";
export var ContentInner = "Hierarchy-module--ContentInner--b0142";
export var Department = "Hierarchy-module--Department--5c57a";
export var DepartmentsRow = "Hierarchy-module--DepartmentsRow--4e895";
export var Employee = "Hierarchy-module--Employee--0b1e6";
export var EmployeesRow = "Hierarchy-module--EmployeesRow--949d0";
export var Hierarchy = "Hierarchy-module--Hierarchy--2f60f";
export var HierarchyButtons = "Hierarchy-module--HierarchyButtons--c724e";
export var HierarchyInner = "Hierarchy-module--HierarchyInner--06faf";
export var HierarchyInputs = "Hierarchy-module--HierarchyInputs--d04a1";
export var HierarchySide = "Hierarchy-module--HierarchySide--f9cf0";
export var Inputs = "Hierarchy-module--Inputs--308b4";
export var Manager = "Hierarchy-module--Manager--9c547";
export var Team = "Hierarchy-module--Team--c9757";
export var TeamsRow = "Hierarchy-module--TeamsRow--af633";