import React, { useContext, useState } from 'react';
import * as styles from './Directory.module.scss';
import { Employee, CalendarEvent } from '../../../Classes/Employee';
import { EmploymentStatus, StatusColours } from '../../../Enums/EmploymentStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faBirthdayCake, faBusinessTime, faCalendarDay, faChevronDown, faMars, faPhone, faSearch, faThList, faUsers, faVenus, faVenusMars } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { OrganisationContext } from '../../../Context/UserContext';
import UnassignedTitle from '../../../components/UnassignedTitle/UnassignedTitle';

const PortalDirectory: React.FC = () => {
  const { Organisation } = useContext(OrganisationContext);
  const [search, setSearch] = useState<string>("");
  const [department, setDepartment] = useState<number>(0);
  const alphabet: string[] = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];

  const SortByAlphabet = (Letter: string) => {
    return FilterEmployees(Letter).sort((a, b) => a.Personal.Surname.localeCompare(b.Personal.Surname))
  }

  const FilterAlphabet = () => {
    return alphabet.filter(letter => FilterEmployees(letter).length);
  }

  const FilterEmployees = (Letter: string) => {
    return Organisation.Employees.filter(e => `${e.Personal.Title ? `${e.Personal.Title}. ` : ""}${e.Personal.Forename} ${e.Personal.Surname}`.toLowerCase().includes(search.toLowerCase()) && e.Personal.Surname.toLowerCase().startsWith(Letter) && !StatusColours.find(s => s.Status === e.Status)?.isDismissed && (department ? e.Business.Department.ID === department : true))
  }

  return (<>
    <div className={styles.Main}>
      <div className={styles.MainHeader}>
        <i>
          <FontAwesomeIcon icon={faSearch} />
        </i>
        <input placeholder='Search...' type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
        <select value={department.toString()} onChange={(e) => setDepartment(parseInt(e.target.value))}>
          <option value="0">- Select Department -</option>
          {Organisation.Departments.map(d => {
            return <option value={d.ID}>{d.Title}</option>
          })}
        </select>
        <i>
          <FontAwesomeIcon icon={faChevronDown} />
        </i>
      </div>
      {
        FilterAlphabet().map(letter => {
          return <>
            <h2>{letter.toUpperCase()}</h2>
            {
              SortByAlphabet(letter).filter(e => !StatusColours.find(s => s.Status === e.Status)?.isDismissed).map((emp: Employee, i: number) => {
                return <EmployeeBlock Employee={emp} />
              })
            }
          </>
        })
      }
    </div>
  </>);
};

interface EmployeeProps {
  Employee: Employee;
}

export const EmployeeBlock: React.FC<EmployeeProps> = ({ Employee }) => {
  const dayList: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const GetStatus = () => {
    let events: CalendarEvent[] = Employee.CalendarEvents.filter(ce => +new Date() <= +new Date(ce.EndDate) && +new Date() >= +new Date(ce.StartDate));
    if (events.length)
      return StatusColours.filter(c => events.find(e => e.Type === c.Status));
    return StatusColours.filter(c => c.Status === Employee.Status);
  }

  const ListDays = () => {
    let days: string = "";
    if (Employee.Business.CurrentEmployment.WorkDays)
      JSON.parse(Employee.Business.CurrentEmployment.WorkDays).map((d: { Day: string | number; }) => {
        days += `${days ? ", " : ""}${dayList[d.Day]}`
      })
    return days;
  }

  const GetIdentity = (Gender: string) => {
    switch (Gender) {
      case "FEMALE":
        return "(she/her)"
      case "MALE":
        return "(he/him)"
      case "NON BINARY":
        return "(them/they)"
      case "GENDER FLUID":
        return "(them/they, he/him, she/her)"
    }
  }

  const getAge = (dateString: string) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return <div className={styles.Employee}>
    <div className={styles.EmployeeInner}>
      <div className={styles.EmployeeImage} style={Employee.ID ? { backgroundImage: `url(${Employee.Personal.Image})` } : {}}>
        {
          Employee.ID ? Employee.Personal.Image ? <></> : <h1>{Employee.Personal.Forename.split("")[0]}{Employee.Personal.Surname.split("")[0]}</h1> : <></>
        }

        <div className={styles.EmployeeStatus} style={Employee.Status ? { backgroundColor: StatusColours.find(c => c.Status === Employee.Status)?.Colour } : {}}></div>
      </div>
      <div className={styles.EmployeeInfo}>
        <h2>{`${Employee.Personal.Title ? `${Employee.Personal.Title}. ` : ""}${Employee.Personal.Forename} ${Employee.Personal.Surname}`}</h2>
        <h3>{Employee.Business.CurrentEmployment.JobTitle ? Employee.Business.CurrentEmployment.JobTitle : <UnassignedTitle />}</h3>
        {
          GetStatus().map(s => {
            return <span style={{ backgroundColor: s.Colour }}><b style={{ color: s.Colour }}>{EmploymentStatus[s.Status]}</b></span>
          })
        }
      </div>
      <div className={styles.EmployeeInfo}>
        <h4>
          <i>
            <FontAwesomeIcon icon={faAt} />
          </i>
          <b>{Employee.Business.Email ? Employee.Business.Email : <i>No Work Email</i>}</b>
        </h4>
        <h4>
          <i>
            <FontAwesomeIcon icon={faPhone} />
          </i>
          <b>{Employee.Business.Phone ? Employee.Business.Phone : <i>No Work Phone</i>}</b>
        </h4>
        <h4>
          <i>
            <FontAwesomeIcon icon={faCalendarDay} />
          </i>
          <b>{ListDays() ? ListDays() : <i>No Assigned Work Days</i>}</b>
        </h4>
      </div>
      <div className={styles.EmployeeInfo}>
        <h4>
          <i>
            <FontAwesomeIcon icon={faThList} />
          </i>
          <b>{Employee.Business.Department.ID ? Employee.Business.Department.Title : <i>No Department Assigned</i>}</b>
        </h4>
        <h4>
          <i>
            <FontAwesomeIcon icon={faUsers} />
          </i>
          <b>{Employee.Business.Team.ID ? Employee.Business.Team.Title : <i>No Team Assigned</i>}</b>
        </h4>
        <h4>
          <i>
            <FontAwesomeIcon icon={faBusinessTime} />
          </i>
          <b>{Employee.Business.StartDate ? `${getAge(Employee.Business.StartDate)} Years Employed` : <i>No Start Date Set</i>}</b>
        </h4>
      </div>
      <div className={styles.EmployeeInfo}>
        <h4>
          <i>
            <FontAwesomeIcon icon={Employee.Personal.Gender ? Employee.Personal.Gender === "MALE" ? faMars : Employee.Personal.Gender === "FEMALE" ? faVenus : faVenusMars : faVenusMars} />
          </i>
          <b>{Employee.Personal.Gender ? `${Employee.Personal.Gender} ${GetIdentity(Employee.Personal.Gender)}` : <i>No Gender Set</i>}</b>
        </h4>
        {!Employee.Personal.hideDateOfBirth ? <>
          <h4>
            <i>
              <FontAwesomeIcon icon={faCalendarCheck} />
            </i>
            <b>{Employee.Personal.DateOfBirth ? `${new Date(Employee.Personal.DateOfBirth).toDateString()}` : <i>No Birthday Set</i>}</b>
          </h4>
          <h4>
            <i>
              <FontAwesomeIcon icon={faBirthdayCake} />
            </i>
            <b>{Employee.Personal.DateOfBirth ? `${getAge(Employee.Personal.DateOfBirth)} Years Old` : <i>No Birthday Set</i>}</b>
          </h4>
        </> : <></>}
      </div>
    </div>
  </div>
};

export default PortalDirectory;
