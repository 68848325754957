// extracted by mini-css-extract-plugin
export var Active = "Policy-Library-module--Active--2929e";
export var Advert = "Policy-Library-module--Advert--5c9b4";
export var AdvertImage = "Policy-Library-module--AdvertImage--ac755";
export var AdvertInfo = "Policy-Library-module--AdvertInfo--b9db2";
export var Adverts = "Policy-Library-module--Adverts--7df4f";
export var Body = "Policy-Library-module--Body--b6395";
export var Document = "Policy-Library-module--Document--d6ab1";
export var DocumentInner = "Policy-Library-module--DocumentInner--f1299";
export var Documents = "Policy-Library-module--Documents--d51e0";
export var DocumentsInner = "Policy-Library-module--DocumentsInner--4aaec";
export var Green = "Policy-Library-module--Green--bb4ea";
export var Loading = "Policy-Library-module--Loading--3b1dd";
export var Nav = "Policy-Library-module--Nav--8ad1f";
export var NavInner = "Policy-Library-module--NavInner--10ef6";
export var Search = "Policy-Library-module--Search--c574e";
export var SearchInput = "Policy-Library-module--SearchInput--dbd74";
export var Section = "Policy-Library-module--Section--5ec79";
export var SectionInner = "Policy-Library-module--SectionInner--8e5ac";
export var Table = "Policy-Library-module--Table--feaab";
export var TableRow = "Policy-Library-module--TableRow--118af";
export var Tabs = "Policy-Library-module--Tabs--ab48e";
export var TabsInner = "Policy-Library-module--TabsInner--5d3ee";