import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React from 'react';
import * as styles from './Breadcrumb.module.scss';
import { BreadcrumbProps } from './Props';

interface Props {
  Trail: BreadcrumbProps[];
}

const Breadcrumb: React.FC<Props> = ({ Trail }) => {
  return (<div className={styles.Breadcrumb}>
    <div className={styles.BreadcrumbInner}>
      <div className={styles.BreadcrumbItem}>
        <Link to="/">
          Home
        </Link>
      </div>

      {
        Trail.map((trailItem: BreadcrumbProps) => {
          return <div className={styles.BreadcrumbItem} key={`${trailItem.Text}`}>
            <i><FontAwesomeIcon icon={faChevronRight} /></i>

            {trailItem.To ? <Link to={trailItem.To}>
              {trailItem.Text}
            </Link> : <p>{trailItem.Text}</p>}
          </div>
        })
      }
    </div>
  </div>);
};

export default Breadcrumb;
