// extracted by mini-css-extract-plugin
export var Active = "Team-module--Active--e5fcc";
export var BANKHOLIDAY = "Team-module--BANKHOLIDAY--8757a";
export var BIRTHDAY = "Team-module--BIRTHDAY--c6fa5";
export var Back = "Team-module--Back--f2393";
export var BankHolidays = "Team-module--BankHolidays--81791";
export var Department = "Team-module--Department--d4dbd";
export var Employee = "Team-module--Employee--b6d2a";
export var EmployeeImage = "Team-module--EmployeeImage--ac7c7";
export var EmployeeInfo = "Team-module--EmployeeInfo--cec2d";
export var EmployeeLinks = "Team-module--EmployeeLinks--62e09";
export var EmployeeStatus = "Team-module--EmployeeStatus--4b2e3";
export var Event = "Team-module--Event--5ec00";
export var Events = "Team-module--Events--02697";
export var EventsBlock = "Team-module--EventsBlock--bbf35";
export var Filter = "Team-module--Filter--64595";
export var Full = "Team-module--Full--dbf9f";
export var Half = "Team-module--Half--38d4e";
export var Header = "Team-module--Header--cb85d";
export var Main = "Team-module--Main--c253f";
export var MainInner = "Team-module--MainInner--4717e";
export var MainOuter = "Team-module--MainOuter--3e679";
export var Message = "Team-module--Message--76755";
export var Show = "Team-module--Show--4580a";
export var Side = "Team-module--Side--f0124";
export var Stats = "Team-module--Stats--1cf06";
export var StatsItem = "Team-module--StatsItem--6bdb1";
export var Tabs = "Team-module--Tabs--ce9cd";
export var TaskInner = "Team-module--TaskInner--ed191";
export var Tasklist = "Team-module--Tasklist--d50b7";
export var Tasks = "Team-module--Tasks--b222a";
export var Unselected = "Team-module--Unselected--90c73";
export var Updates = "Team-module--Updates--ddf82";
export var UpdatesBlock = "Team-module--UpdatesBlock--dd090";
export var UpdatesInner = "Team-module--UpdatesInner--0209a";
export var UpdatesItem = "Team-module--UpdatesItem--47f71";
export var UpdatesItemInner = "Team-module--UpdatesItemInner--9a7d2";