import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Employee.module.scss';
import * as general from '../Styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { EmployeeContext } from '../../../Context/UserContext';
import { Employee } from '../../../Classes/Employee';
import Input from '../../../components/Input/input';
import { Loading } from '../../../components/Loading/Loading';
import { API_SaveEmployee } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import { EmployeeAccountTabs } from './Account';
import { Button } from '../../Portal/Courses/Components/Button/Button';

const EmployeePortalAccountCorporate: React.FC = () => {
  const { Employee, setEmployee } = useContext(EmployeeContext);
  const [employee, setEmployeeInformation] = useState<Employee>({} as Employee);
  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);

  const dayList: number[] = [0, 1, 2, 3, 4, 5, 6];
  const days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  useEffect(() => {
    if (Employee.ID) {
      setEmployeeInformation(Employee);
    }
  }, [Employee])

  useEffect(() => {
    if (Employee.ID) {
      let total: number = 0;

      if (Employee.Business.CurrentEmployment.WorkDays)
        JSON.parse(Employee.Business.CurrentEmployment.WorkDays).map(d => {
          total += d.Hours ? d.Hours : 0;
        })

      total = parseFloat(total.toFixed(1));
      setEmployee({ ...Employee, Business: { ...Employee.Business, CurrentEmployment: { ...Employee.Business.CurrentEmployment, ContractedHours: total.toFixed(1) } } });
    }
  }, [Employee.Business && Employee.Business.CurrentEmployment.WorkDays]);

  const Save = async () => {
    setSaving(true);
    let empData: Employee = JSON.parse(JSON.stringify(employee));
    await Fetch(`${API_SaveEmployee}`, empData).then((emp: Employee) => {
      setEmployee(emp);
      setSaved(true);
      setSaving(false);
      setTimeout(() => {
        setSaved(false);
      }, 2000)
    });
  }

  return (<div className={general.Main}>
    <div className={styles.Body}>
      <EmployeeAccountTabs {...Employee} />

      <div className={styles.Body}>
        <div className={styles.FormOuter}>
          <div className={general.Header}>
            <div className={general.Search}>

            </div>

            <Button Type="button" Color={saved ? "Green" : "Theme"} OnClick={() => Save()} Disabled={saving}>
              <i>
                <FontAwesomeIcon icon={saving ? faSpinner : saved ? faCheckDouble : faSave} spin={saving} />
              </i>
              {saving ? "Saving..." : saved ? "Saved!" : "Save Account"}
            </Button>
          </div>

          <form autoComplete="off" className={styles.Form}>
            {!employee.ID ? <div className={styles.Loading}><Loading /></div> : <>
              <div className={styles.FormInner}>
                <Input Type="text" Value={employee.Business.NationalInsuranceNumber} OnChange={(v) => { }} Placeholder="..." Label="National Insurance Number" />
                <Input Width="half" Type="text" Value={employee.Business.Phone} OnChange={(v) => setEmployeeInformation({ ...employee, Business: { ...employee.Business, Phone: v } })} Placeholder="..." Label="Company Phone Number" />
                <Input Width="half" Type="text" Value={employee.Business.Email} OnChange={(v) => setEmployeeInformation({ ...employee, Business: { ...employee.Business, Email: v } })} Placeholder="..." Label="Company Email" />
                <Input Width="half" Type="text" Value={employee.Personal.AccountNumber} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, AccountNumber: v } })} Placeholder="..." Label="Account Number" />
                <Input Width="half" Type="text" Value={employee.Personal.SortCode} OnChange={(v) => setEmployeeInformation({ ...employee, Personal: { ...employee.Personal, SortCode: v } })} Placeholder="..." Label="Sort Code" />

                <hr />

                <Input Disabled={true} Type="text" Value={employee.Business.CurrentEmployment.JobTitle} OnChange={(v) => { }} Placeholder="..." Label="Job Title" />
                <Input Disabled={true} Width="half" Type="date" Value={employee.Business.StartDate} OnChange={(v) => { }} Placeholder="..." Label="Start Date" />
                <Input Disabled={true} Width="half" Type="text" Value={employee.Business.Department.Title} OnChange={(v) => { }} Placeholder="..." Label="Department" />
                <Input Disabled={true} Width="half" Type="text" Value={employee.Business.Team.Title} OnChange={(v) => { }} Placeholder="..." Label="Team" />
                <Input Disabled={true} Width="half" Type="text" Value={employee.Business.CurrentEmployment.EmploymentType} OnChange={(v) => { }} Placeholder="..." Label="Employment Type" />
                <Input Disabled={true} Width="half" Type="date" Value={employee.Business.CurrentEmployment.StartDate} OnChange={(v) => { }} Placeholder="..." Label="Start Date" />
                <Input Disabled={true} Width="half" Type="text" Value={employee.Business.CurrentEmployment.ContractedHours} OnChange={(v) => { }} Placeholder="..." Label="Contracted Hours Per Week" />

                <hr />

                <Input Disabled={true} Width="half" Type="text" Value={employee.Business.CurrentEmployment.TaxCode} OnChange={(v) => { }} Placeholder="..." Label="Tax Code" />
                <Input Disabled={true} Width="half" Type="text" Value={"£" + employee.Business.CurrentEmployment.HourlyPay} OnChange={(v) => { }} Placeholder="..." Label="Hourly Pay" />
                <Input Disabled={true} Width="half" Type="text" Value={"£" + employee.Business.CurrentEmployment.DailyPay} OnChange={(v) => { }} Placeholder="..." Label="Daily Pay" />
                <Input Disabled={true} Width="half" Type="text" Value={"£" + employee.Business.CurrentEmployment.WeeklyPay} OnChange={(v) => { }} Placeholder="..." Label="Weekly Pay" />
                <Input Disabled={true} Width="half" Type="text" Value={"£" + employee.Business.CurrentEmployment.MonthlyPay} OnChange={(v) => { }} Placeholder="..." Label="Monthly Pay" />
                <Input Disabled={true} Width="half" Type="text" Value={"£" + employee.Business.CurrentEmployment.YearlyPay} OnChange={(v) => { }} Placeholder="..." Label="Yearly Pay" />
              </div>
              {employee.Business.CurrentEmployment.EmploymentType === "ROTA BASED" ? <></> : <div className={styles.Checkboxes}>
                <h1>Workdays</h1>

                {employee.Business.CurrentEmployment.worksBankHolidays ? <div className={styles.Message}>
                  You are assigned to also work all Bank Holidays that coincide with your work days
                </div> : <></>}

                {
                  dayList.filter(d => employee.Business.CurrentEmployment.WorkDays && JSON.parse(employee.Business.CurrentEmployment.WorkDays).find(n => n.Day === d) ? true : false).map(d => {
                    return <div className={styles.Checkbox}>
                      <span>{days[d]}</span>
                      <span>{employee.Business.CurrentEmployment.WorkDays && JSON.parse(employee.Business.CurrentEmployment.WorkDays).find(n => n.Day === d) ? JSON.parse(employee.Business.CurrentEmployment.WorkDays).find(n => n.Day === d).Hours : "0"} Hours</span>
                    </div>
                  })
                }
              </div>}
            </>}
          </form>
        </div>
      </div>
    </div>
  </div>);
};

export default EmployeePortalAccountCorporate;
