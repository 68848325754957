import React, { useContext } from 'react';
import * as general from '../Styles.module.scss';
import { EmployeeContext } from '../../../Context/UserContext';
import { Loading } from '../../../components/Loading/Loading';
import { EmployeeAccountTabs } from './Account';
import * as other from './HolidayRequests/HolidayRequests.module.scss';
import AssetManagement from '../../Portal/Assets/Assets';
import { EncryptValue } from '../../../Services/Crypto';

const EmployeePortalAssets: React.FC = () => {
  const { Employee } = useContext(EmployeeContext);

  return (<div className={general.Main}>
    {Employee.ID ? <div className={general.Content}>
      <EmployeeAccountTabs {...Employee} />

      <div className={other.ContentInner}>
        <AssetManagement CustomerID={""} ID={EncryptValue(Employee.ID.toString())} />
      </div>
    </div> : <div className={general.Loading}><Loading /></div>}
  </div>);
};

export default EmployeePortalAssets;
