import React, { useContext } from 'react';
import * as styles from './HolidayRequests.module.scss';
import * as general from '../Employee.module.scss';
import * as main from '../../Styles.module.scss';
import { EmployeeContext } from '../../../../Context/UserContext';
import { Loading } from '../../../../components/Loading/Loading';
import EmployeeHolidayRequests from '../../Holiday-Requests/Holiday-Requests';
import { EmployeeAccountTabs } from '../Account';

const EmployeeAccountHolidays: React.FC = (props: any) => {
  const RefID: string = props.RefID ? props.RefID : "";
  const { Employee } = useContext(EmployeeContext);

  return (<>
    <div className={main.Main}>
      {Employee.ID ? <div className={main.Content}>
        <EmployeeAccountTabs {...Employee} />

        <div className={styles.ContentInner}>
          <EmployeeHolidayRequests OwnAccount={true} Employee={Employee} RefID={RefID} />
        </div>
      </div> : <div className={general.Loading}><Loading /></div>}
    </div>
  </>);
};

export default EmployeeAccountHolidays;
