import React, { useContext, useEffect, useState } from 'react';
import * as styles from './CompanyCalendar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faCalendarWeek, faChevronLeft, faChevronRight, faFilter, faMedal, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { EmploymentStatus, StatusColour, StatusColours } from '../../../Enums/EmploymentStatus';
import { OrganisationContext } from '../../../Context/UserContext';
import { CalendarEvent, CalendarSettings, Employee } from '../../../Classes/Employee';

const EmployeePortalCompanyCalendar: React.FC = () => {
  const month = new Date().getMonth() + 1;
  const months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const dayList: number[] = [0, 1, 2, 3, 4, 5, 6];
  const days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const types: StatusColour[] = StatusColours;

  const { Organisation } = useContext(OrganisationContext);
  const [yearDays, setYearDays] = useState<Date[]>([] as Date[]);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [Month, setMonth] = useState<number>(month);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [employeesFilter, setEmployeesFilter] = useState<number[]>([] as number[]);
  const [typesFilter, setTypesFilter] = useState<string[]>([] as string[]);
  const [showFilterItem, setShowFilterItem] = useState<number[]>([] as number[]);
  const [dayFilter, setDayFilter] = useState<number[]>(dayList);
  const [showBankHolidays, setShowBankHolidays] = useState<boolean>(true);

  useEffect(() => {
    if (Organisation)
      Reset();
  }, [Organisation])

  useEffect(() => {
    if (yearDays)
      document.getElementById(`day-${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}`)?.scrollIntoView({
        behavior: 'smooth',
        inline: 'end',
        block: "center"
      });
  }, [yearDays])

  useEffect(() => {
    var dates: Date[] = [] as Date[];

    let firstDay: number = new Date(year, Month - 1, 1).getDay();
    let lastDay: Date = new Date(Month === 12 ? year + 1 : year, Month === 12 ? 0 : Month, 0);
    let startDay: Date = new Date(new Date(year, Month - 1, 1).setDate(-firstDay + 1));
    let finalDay: Date = new Date(lastDay.setDate(lastDay.getDate() + (6 - lastDay.getDay())));

    for (var d = startDay; d <= finalDay; d.setDate(d.getDate() + 1)) {
      dates.push(new Date(d));
    }

    setYearDays(dates);
  }, [year, Month])

  const GetClass = (CurrDate?: Date) => {
    let classList: string = ` `;
    let title: any = "";
    let colour: string = '';
    if (CurrDate) {
      title = CurrDate.toDateString();

      if (CurrDate.toLocaleDateString(window.navigator.language) === new Date().toLocaleDateString(window.navigator.language))
        classList += `${styles.Today} `;
      if (CurrDate.getMonth() !== (Month - 1))
        classList += `${styles.Blank} `;
      if (Organisation.BankHolidays.find(b => b.Year === year && b.Days.find(da => new Date(da.date).toDateString() === CurrDate.toDateString()))) {
        classList += `${styles.BankHoliday} `;
        title = <>
          {title}
          <small>{Organisation.BankHolidays.find(b => b.Year === year && b.Days.find(da => new Date(da.date).toDateString() === CurrDate.toDateString()))?.Days.find(da => new Date(da.date).toDateString() === CurrDate.toDateString())?.title}</small>
        </>
      }
      if (CurrDate.getDay() === 0 || CurrDate.getDay() === 6) {
        classList += `${styles.Weekend} `;
      }
    }
    return { Class: classList, Title: title, Colour: colour };
  }

  const FilterEmployees = (ID: number) => {
    let filter: number[] = JSON.parse(JSON.stringify(employeesFilter));
    let index: number = filter.findIndex(f => f === ID);
    if (index >= 0)
      filter.splice(index, 1);
    else
      filter.push(ID);
    setEmployeesFilter(filter);
  }

  const FilterTypes = (Type: string) => {
    let filter: string[] = JSON.parse(JSON.stringify(typesFilter));
    let index: number = filter.findIndex(f => f === Type);
    if (index >= 0)
      filter.splice(index, 1);
    else
      filter.push(Type);
    setTypesFilter(filter);
  }

  const SimpleDate = (ThisDate: Date) => {
    return new Date(`${ThisDate.getFullYear()}-${(ThisDate.getMonth() + 1) < 10 ? "0" : ""}${(ThisDate.getMonth() + 1)}-${ThisDate.getDate() < 10 ? "0" : ""}${ThisDate.getDate()}`);
  }

  const GetEmployees = (Selected: Date) => {
    let Employees: Employee[] = [];
    let date: Date = SimpleDate(Selected);

    Organisation.Employees.map((e: Employee) => {
      let eventList: CalendarEvent[] = [] as CalendarEvent[];

      e.CalendarEvents.filter((event: CalendarEvent) => (+date >= +SimpleDate(new Date(event.StartDate)) && +date <= +SimpleDate(new Date(event.EndDate))) && StatusColours.find(c => c.Status === event.Type)?.canEmployee && typesFilter.includes(event.Type)).map((event: CalendarEvent) => {
        let settings: CalendarSettings[] = JSON.parse(event.Settings);

        if (e.Business.HolidayEntitlement.TimeOffUnit === "Hours") {
          let daySetting: CalendarSettings | undefined = settings.find((s: CalendarSettings) => +SimpleDate(new Date(s.Day)) === +date);
          if (daySetting && daySetting.Hours) {
            eventList.push(event);
          }
        } else {
          eventList.push(event);
        }
      });

      if (eventList.length) {
        Employees.push(e);
      }
    });

    return Employees;
  }

  const FilterItem = (Section: number) => {
    let filter: number[] = JSON.parse(JSON.stringify(showFilterItem));
    let index: number = filter.findIndex(f => f === Section);
    if (index >= 0)
      filter.splice(index, 1);
    else
      filter.push(Section);
    setShowFilterItem(filter);
  }

  const FilterDays = (Day: number) => {
    let filter: number[] = JSON.parse(JSON.stringify(dayFilter));
    let index: number = filter.findIndex(f => f === Day);
    if (index >= 0)
      filter.splice(index, 1);
    else
      filter.push(Day);
    setDayFilter(filter);
  }

  const Reset = () => {
    setEmployeesFilter(Organisation.Employees.filter(e => StatusColours.find(c => !c.isDismissed && c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))).map(e => { return e.ID }));
    setTypesFilter(StatusColours.filter(s => !s.isDismissed && s.canEmployee).map(s => { return s.Status }));
    setDayFilter(dayList);
    setShowBankHolidays(true);
  }

  const getEventsByDay = (CalendarEvents: CalendarEvent[], date: Date, Employee: Employee) => {
    let eventList: CalendarEvent[] = [] as CalendarEvent[];

    CalendarEvents.filter((event: CalendarEvent) => (+date >= +SimpleDate(new Date(event.StartDate)) && +date <= +SimpleDate(new Date(event.EndDate))) && StatusColours.find(c => c.Status === event.Type)?.canEmployee && typesFilter.includes(event.Type)).map((event: CalendarEvent) => {
      let settings: CalendarSettings[] = JSON.parse(event.Settings);

      if (Employee.Business.HolidayEntitlement.TimeOffUnit === "Hours") {
        let daySetting: CalendarSettings | undefined = settings.find((s: CalendarSettings) => +SimpleDate(new Date(s.Day)) === +SimpleDate(date));
        if (daySetting && daySetting.Hours) {
          eventList.push(event);
        }
      } else {
        eventList.push(event);
      }
    });

    return eventList;
  }

  return (<>
    <div className={styles.Content}>
      <div className={styles.Header}>
        <div className={styles.HeaderInner}>
          <button onClick={() => {
            if (Month === 1) {
              setMonth(12);
              setYear(year - 1);
            }
            else
              setMonth(Month - 1);
          }}>
            <i>
              <FontAwesomeIcon icon={faChevronLeft} />
            </i>
          </button>
          <label>
            <select value={Month} onChange={(e) => setMonth(parseInt(e.target.value))}>
              {
                months.map((m, i) => {
                  return <option value={(i + 1)}>{m}, {year}</option>
                })
              }
            </select>
          </label>

          <button onClick={() => {
            if (Month === 12) {
              setMonth(1);
              setYear(year + 1);
            } else
              setMonth(Month + 1);
          }}>
            <i>
              <FontAwesomeIcon icon={faChevronRight} />
            </i>
          </button>
        </div>
        <div className={styles.HeaderFilter}>
          <button onClick={() => setShowFilter(!showFilter)}>
            <i>
              <FontAwesomeIcon icon={faFilter} />
            </i>
            Filter
          </button>

          <div className={`${styles.Filter} ${showFilter ? styles.Show : ""}`}>
            <p>
              <b>Employees ({employeesFilter.length} / {Organisation.Employees.filter(e => StatusColours.find(c => !c.isDismissed && c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))).length})</b>
              <button onClick={() => FilterItem(1)}>
                <i>
                  <FontAwesomeIcon icon={showFilterItem.includes(1) ? faMinus : faPlus} />
                </i>
              </button>
            </p>
            {showFilterItem.includes(1) ? <div className={styles.FilterInner}>
              <p>
                <b>
                  All
                </b>
                <input checked={employeesFilter.length === Organisation.Employees.filter(e => !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).length} type="checkbox" onClick={() => setEmployeesFilter(employeesFilter.length === Organisation.Employees.filter(e => !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).length ? [] : Organisation.Employees.filter(e => !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).map(e => { return e.ID }))} />
              </p>
              {Organisation.Employees.filter(e => !StatusColours.find(c => c.Status === (e.Status ? e.Status : EmploymentStatus.ACTIVE))?.isDismissed).map((emp) => {
                return <p>
                  <b>
                    {emp.Personal.Forename} {emp.Personal.Surname}
                  </b>
                  <input onChange={() => FilterEmployees(emp.ID)} type="checkbox" checked={employeesFilter.includes(emp.ID)} />
                </p>
              })}
            </div> : <></>}
            <p>
              <b>Absence Types ({typesFilter.length} / {StatusColours.filter(s => !s.isDismissed && s.canEmployee).length})</b>
              <button onClick={() => FilterItem(2)}>
                <i>
                  <FontAwesomeIcon icon={showFilterItem.includes(2) ? faMinus : faPlus} />
                </i>
              </button>
            </p>
            {showFilterItem.includes(2) ? <div className={styles.FilterInner}>
              <p>
                <b>
                  All
                </b>
                <input checked={typesFilter.length === StatusColours.filter(s => !s.isDismissed && s.canEmployee).length} type="checkbox" onClick={() => setTypesFilter(typesFilter.length === StatusColours.filter(s => !s.isDismissed && s.canEmployee).length ? [] : StatusColours.filter(s => !s.isDismissed && s.canEmployee).map(s => { return s.Status }))} />
              </p>
              {StatusColours.filter(s => !s.isDismissed && s.canEmployee).map((s) => {
                return <p>
                  <b>
                    {EmploymentStatus[s.Status].toLowerCase()}
                  </b>
                  <input onChange={() => FilterTypes(s.Status)} type="checkbox" checked={typesFilter.includes(s.Status)} />
                </p>
              })}
            </div> : <></>}
            <p>
              <b>Days ({dayFilter.length} / {dayList.length})</b>
              <button onClick={() => FilterItem(3)}>
                <i>
                  <FontAwesomeIcon icon={showFilterItem.includes(3) ? faMinus : faPlus} />
                </i>
              </button>
            </p>
            {showFilterItem.includes(3) ? <div className={styles.FilterInner}>
              <p>
                <b>
                  All
                </b>
                <input checked={dayFilter.length === dayList.length} type="checkbox" onClick={() => setDayFilter(dayFilter.length === dayList.length ? [] : dayList)} />
              </p>
              {days.map((d, i) => {
                return <p>
                  <b>
                    {d}
                  </b>
                  <input onChange={() => FilterDays(i)} type="checkbox" checked={dayFilter.includes(i)} />
                </p>
              })}
            </div> : <></>}
            <p>
              <b>Extras</b>
              <button onClick={() => FilterItem(4)}>
                <i>
                  <FontAwesomeIcon icon={showFilterItem.includes(4) ? faMinus : faPlus} />
                </i>
              </button>
            </p>
            {showFilterItem.includes(4) ? <div className={styles.FilterInner}>
              <p>
                <b>
                  Bank Holidays
                </b>
                <input onChange={(e) => setShowBankHolidays(e.target.checked)} type="checkbox" checked={showBankHolidays} />
              </p>
            </div> : <></>}

            <button onClick={() => Reset()}>Reset Filters</button>
          </div>
        </div>
      </div>
      <div className={styles.ContentInner}>
        <div className={styles.Calendar}>
          <div className={styles.CalendarHeader}>
            {
              days.map((day, index) => {
                if (dayFilter.includes(index))
                  return <h2>{day}</h2>
              })
            }
          </div>
          <div className={styles.CalendarInner}>
            {
              days.map((_, index) => {
                if (dayFilter.includes(index))
                  return <div className={styles.CalendarRow}>
                    {
                      yearDays.filter(d => d.getDay() === index).map(d => {
                        return <div id={`day-${d.getDate()}-${d.getMonth()}-${d.getFullYear()}`} className={`${styles.CalendarDay} ${GetClass(d).Class}`}>
                          <span>
                            <b>{d.toLocaleDateString(window.navigator.language)}</b>
                            {showBankHolidays && Organisation.BankHolidays.find((b) => b.Year === d.getFullYear() && b.Days.find(day => day.date === `${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? `0` : ""}${(d.getMonth() + 1)}-${d.getDate() < 10 ? `0` : ""}${d.getDate()}`)) ? <i title={`${Organisation.BankHolidays.find((b) => b.Year === d.getFullYear() && b.Days.find(day => day.date === `${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? `0` : ""}${(d.getMonth() + 1)}-${d.getDate() < 10 ? `0` : ""}${d.getDate()}`))?.Days.find(bh => bh.date === `${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? `0` : ""}${(d.getMonth() + 1)}-${d.getDate() < 10 ? `0` : ""}${d.getDate()}`)?.title}`}>
                              <FontAwesomeIcon icon={faCalendarWeek} />
                            </i> : <></>}
                          </span>
                          {GetEmployees(d).map((e) => {
                            return <>
                              {+SimpleDate(d) === +new Date(`${year}-${(new Date(e.Personal.DateOfBirth).getMonth() + 1) < 10 ? `0` : ""}${new Date(e.Personal.DateOfBirth).getMonth() + 1}-${new Date(e.Personal.DateOfBirth).getDate() < 10 ? `0` : ""}${new Date(e.Personal.DateOfBirth).getDate()}`) ? <div className={styles.EmployeeEvent}>
                                <b style={{ backgroundColor: `#ba68c8` }}>
                                  <span style={{ color: `#ba68c8` }}>{e.Personal.Forename} {e.Personal.Surname} <i><FontAwesomeIcon icon={faBirthdayCake} /> </i></span>
                                </b>
                              </div> : <></>}

                              {+SimpleDate(d) === +new Date(`${year}-${(new Date(e.Business.StartDate).getMonth() + 1) < 10 ? `0` : ""}${new Date(e.Business.StartDate).getMonth() + 1}-${new Date(e.Business.StartDate).getDate() < 10 ? `0` : ""}${new Date(e.Business.StartDate).getDate()}`) ? <div className={styles.EmployeeEvent}>
                                <b style={{ backgroundColor: `#ff9800` }}>
                                  <span style={{ color: `#ff9800` }}>{e.Personal.Forename} {e.Personal.Surname} <i><FontAwesomeIcon icon={faMedal} /> </i></span>
                                </b>
                              </div> : <></>}

                              {
                                getEventsByDay(e.CalendarEvents, SimpleDate(d), e).map((ce) => {
                                  return <div className={styles.EmployeeEvent}>
                                    <b className={(ce.ApprovedBy === 0 || ce.ApprovedBy === 2) && ce.Type === ("ANNUAL_LEAVE" as EmploymentStatus) ? styles.Unapproved : ""} style={{ backgroundColor: `${types.find(t => t.Status === ce.Type)?.Colour}` }}>
                                      <span style={{ color: `${types.find(t => t.Status === ce.Type)?.Colour}` }}>{e.Personal.Forename} {e.Personal.Surname}</span>
                                    </b>
                                  </div>
                                })
                              }
                            </>
                          })}
                        </div>
                      })
                    }
                  </div>
              })
            }
          </div>
        </div>
      </div>
      <div className={styles.Key}>
        <ul>
          {
            types.filter(t => t.canEmployee).map(t => {
              return <li>
                <span style={{ borderColor: t.Colour }}></span>
                <b>{EmploymentStatus[t.Status]}</b>
              </li>
            })
          }
          <li><span style={{ borderColor: "#ba68c8" }}></span><b>Birthday</b></li>
          <li><span style={{ borderColor: "#ff9800" }}></span><b>Work Anniversary</b></li>
          <li><i><FontAwesomeIcon icon={faCalendarWeek} /></i><b>Bank Holiday</b></li>
        </ul>
      </div>
    </div>
  </>);
};

export default EmployeePortalCompanyCalendar;
