import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Employee.module.scss';
import * as general from '../Styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheckDouble, faChevronRight, faLaptop, faPlus, faSave, faSpinner, faTasks } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faFaceGrinBeam } from '@fortawesome/free-regular-svg-icons';
import { Link, navigate } from 'gatsby';
import { OrganisationContext, UserContext } from '../../../Context/UserContext';
import { Fetch } from '../../../Services/Fetch';
import { Employee, CalendarEvent, ReviewForm, Objective } from '../../../Classes/Employee';
import { EmploymentStatus, StatusColours } from '../../../Enums/EmploymentStatus';
import { API_SaveObjective, API_SaveReviewForm } from '../../../Services/ApiRoutes';
import { DecryptValue } from '../../../Services/Crypto';
import { Loading } from '../../../components/Loading/Loading';
import EmployeePortalWorklog from '../Account/Worklog/Worklog';
import EmployeeHolidayRequests from '../Holiday-Requests/Holiday-Requests';
import Input from '../../../components/Input/input';
import { Modal } from '../../../components/Modal/Modal';
import { ObjectiveColours, ObjectiveType } from '../../../Enums/ObjectiveType';
import EmployeeHappiness from '../Happiness/Happiness';
import PortalTaskManager from '../../Portal/Employees/TaskManager/TaskManager';
import AssetManagement from '../../Portal/Assets/Assets';
import { Button } from '../../Portal/Courses/Components/Button/Button';

const EmployeePortalEmployee: React.FC = (props: any) => {
  const ID: string = props.ID ? props.ID : "";
  const Tab: string = props.Tab ? decodeURIComponent(props.Tab).toLowerCase() : "personal";
  const RefID: string = props.RefID ? props.RefID : "";

  let defaultObjective: Objective = {
    ID: 0,
    EmployeeID: 0,
    Title: "",
    Type: ObjectiveType.PROBATION,
    Description: ""
  } as Objective
  let defaultReviewForm: ReviewForm = {
    ID: 0,
    EmployeeID: 0,
    Quality: 0,
    Knowledge: 0,
    Effiency: 0,
    Attendance: 0,
    TimeKeeping: 0,
    Relationships: 0,
    Competency: 0,
    Feedback: "",
    ObjectivesMet: false,
    ObjectivesAction: "",
    Training: false,
    TrainingAction: "",
    ProbationSuccess: false,
    NoReason: "",
    Extended: false,
    ExtendedReason: "",
    NewEndDate: ""
  } as ReviewForm

  const { User, Language } = useContext(UserContext);
  const { Organisation } = useContext(OrganisationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [employee, setEmployee] = useState<Employee>({} as Employee);
  const [showObjectives, setShowObjectives] = useState<boolean>(false);
  const [showReviewForm, setShowReviewForm] = useState<boolean>(false);
  const [addObjective, setAddObjective] = useState<boolean>(false);
  const [objective, setObjective] = useState<Objective>(defaultObjective);
  const [reviewForm, setReviewForm] = useState<ReviewForm>(defaultReviewForm);
  const rating: number[] = [1, 2, 3, 4, 5];

  useEffect(() => {
    if (User.ID && (ID || User.isEmployee))
      GetEmployees();
  }, [User, ID])

  useEffect(() => {
    if (employee.ID) {
      let total: number = 0;

      if (employee.Business.CurrentEmployment.WorkDays)
        JSON.parse(employee.Business.CurrentEmployment.WorkDays).map(d => {
          total += d.Hours ? d.Hours : 0;
        })

      total = parseFloat(total.toFixed(1));
      setEmployee({ ...employee, Business: { ...employee.Business, CurrentEmployment: { ...employee.Business.CurrentEmployment, ContractedHours: total.toFixed(1) } } });
    }
  }, [employee.Business && employee.Business.CurrentEmployment.WorkDays]);

  const GetEmployees = async () => {
    setLoading(true);

    let empData: Employee | undefined = Organisation.Employees.find(e => e.ID === parseInt((DecryptValue(ID))));

    if (empData) {
      setEmployee(empData);
    } else
      navigate(`/Employee/Portal`);

    setLoading(false);
  }

  const GetStatus = () => {
    let events: CalendarEvent[] = employee.CalendarEvents.filter(ce => +new Date() <= +new Date(ce.EndDate) && +new Date() >= +new Date(ce.StartDate));
    if (events.length)
      return StatusColours.find(c => c.Status === events[0].Type);
    return StatusColours.find(c => (c.Status === employee.Status || c.Status === EmploymentStatus[employee.Status] || EmploymentStatus[c.Status] === EmploymentStatus[employee.Status] || EmploymentStatus[c.Status] === employee.Status)) ? StatusColours.find(c => (c.Status === employee.Status || c.Status === EmploymentStatus[employee.Status] || EmploymentStatus[c.Status] === EmploymentStatus[employee.Status] || EmploymentStatus[c.Status] === employee.Status)) : StatusColours.find(c => c.Status === EmploymentStatus.ACTIVE);
  }

  const SaveObjective = async (thisObjective: Objective) => {
    if (employee.Business.Probation.ProbationEndDate)
      thisObjective.CompleteBy = employee.Business.Probation.ProbationEndDate;
    if (employee.Business.Probation.ReviewForm.Extended && employee.Business.Probation.ReviewForm.NewEndDate)
      thisObjective.CompleteBy = employee.Business.Probation.ReviewForm.NewEndDate;
    thisObjective.EmployeeID = employee.ID;
    thisObjective.Type = "PROBATION";

    await Fetch(`${API_SaveObjective}`, thisObjective).then((obj: Objective) => {
      let objectives: Objective[] = JSON.parse(JSON.stringify(employee.Business.Probation.Objectives));
      if (thisObjective.ID)
        objectives = objectives.map((o) => {
          if (o.ID === obj.ID)
            return obj;
          return o;
        })
      else
        objectives.push(obj);

      setEmployee({ ...employee, Business: { ...employee.Business, Probation: { ...employee.Business.Probation, Objectives: objectives } } });
      setAddObjective(false);
    })
  }

  const SendReview = async (ReviewForm: ReviewForm) => {
    ReviewForm.EmployeeID = employee.ID;

    await Fetch(`${API_SaveReviewForm}`, ReviewForm).then((revForm: ReviewForm) => {
      setEmployee({ ...employee, Business: { ...employee.Business, Probation: { ...employee.Business.Probation, ReviewForm: revForm } } });
      setShowReviewForm(false);
      setReviewForm(defaultReviewForm);
    })
  }

  return (<>
    <div className={general.Main}>
      <div className={general.Header}>
        <div className={general.Search}>
          <h1>{employee && employee.Personal ? `${employee.Personal.Forename} ${employee.Personal.Surname}` : <i><FontAwesomeIcon icon={faSpinner} spin={true} /></i>}</h1>
          <div className={styles.QuickSet}>
            {employee.Status ? <div className={styles.QuickSetDropdown}>
              <span style={{ backgroundColor: GetStatus()?.Colour }}>
                <b style={{ color: GetStatus()?.Colour }}>{EmploymentStatus[GetStatus()?.Status]}</b>
              </span>
            </div> : <></>}
          </div>
          {employee.DateReturn ? <b>Till {new Date(employee.DateReturn).toLocaleDateString(window.navigator.language)}</b> : <></>}
        </div>
      </div>
      <div className={styles.Body}>
        <div className={styles.Tabs}>
          {employee.ID ? <div className={styles.Picture}>
            {employee.Personal.Image ? <img src={employee.Personal.Image} /> : <h1>{employee.Personal.Forename.split("")[0]}{employee.Personal.Surname.split("")[0]}</h1>}
          </div> : <></>}

          <div className={styles.TabsIcons}>
            <Link title='Assets' activeClassName={styles.Active} to={`/Employee/Portal/Employees/Employee/${props.ID}/Assigned Assets`}>
              <i>
                <FontAwesomeIcon icon={faLaptop} />
              </i>
            </Link>
            {User.HappinessOptionsData.length ? <Link title='Mood Monitor' activeClassName={styles.Active} to={`/Employee/Portal/Employees/Employee/${props.ID}/Happiness`}>
              <i>
                <FontAwesomeIcon icon={faFaceGrinBeam} />
              </i>
            </Link> : <></>}
            <Link title='Task Manager' activeClassName={styles.Active} to={`/Employee/Portal/Employees/Employee/${props.ID}/Task Manager`}>
              <i>
                <FontAwesomeIcon icon={faTasks} />
              </i>
            </Link>
          </div>

          <Link activeClassName={styles.Active} to={`/Employee/Portal/Employees/Employee/${props.ID}/Personal`}>
            Personal
          </Link>
          <Link activeClassName={styles.Active} to={`/Employee/Portal/Employees/Employee/${props.ID}/Probation Period`}>
            Probation Period
          </Link>
          <Link activeClassName={styles.Active} to={`/Employee/Portal/Employees/Employee/${props.ID}/Worklog`}>
            Worklog
          </Link>
          <Link activeClassName={styles.Active} to={`/Employee/Portal/Employees/Employee/${props.ID}/Leave Requests`}>
            Leave Requests
          </Link>
        </div>

        <div className={styles.Content}>
          {Tab === "personal" ? <>
            <div className={styles.Form}>
              {employee.ID ? <div className={styles.FormInner}>
                <h1>{Language.Application_Employees.PersonalInformation}</h1>
                <p>This is the {Language.Application_Employees.PersonalInformation} of the Employee. Only the owner of this data can change this information from their PrimeHR Portal.</p>

                <Input Disabled={true} Width="third" Type="text" Value={employee.Personal.Title} OnChange={(v) => { }} Placeholder="..." Label="Title" />
                <Input Disabled={true} Width="third" Type="text" Value={employee.Personal.Forename} OnChange={(v) => { }} Placeholder="..." Label="Forename" />
                <Input Disabled={true} Width="third" Type="text" Value={employee.Personal.Surname} OnChange={(v) => { }} Placeholder="..." Label="Surname" />
                <Input Disabled={true} Width="half" Type="text" Value={employee.Personal.Gender} OnChange={(v) => { }} Placeholder="..." Label="Gender" />
                <Input Disabled={true} Width="half" Type="date" Value={employee.Personal.DateOfBirth} OnChange={(v) => { }} Placeholder="..." Label="Date Of Birth" />
                <Input Disabled={true} Width="half" Type="text" Value={employee.Business.Department.Title} OnChange={(v) => { }} Placeholder="..." Label="Department" />
                <Input Disabled={true} Width="half" Type="text" Value={employee.Business.Team.Title} OnChange={(v) => { }} Placeholder="..." Label="Team" />
                <Input Disabled={true} Width="half" Type="text" Value={employee.Business.Phone} OnChange={(v) => { }} Placeholder="..." Label="Company Phone Number" />
                <Input Disabled={true} Width="half" Type="text" Value={employee.Business.Email} OnChange={(v) => { }} Placeholder="..." Label="Company Email" />
              </div> : <div className={general.Loading}><Loading /></div>}
            </div>
          </> : Tab === "assigned assets" ? <>
            {!employee.ID ? <div className={styles.Loading}><Loading /></div> : <div className={styles.Assets}>
              <AssetManagement CustomerID={props.CustomerID} ID={props.ID} />
            </div>}
          </> : Tab === "task manager" ? <>
            {!employee.ID ? <div className={styles.Loading}><Loading /></div> : <div className={styles.HolidayRequests}>
              <PortalTaskManager ID={employee.ID} Type="Employee" CustomerID={props.CustomerID} />
            </div>}
          </> : Tab === "happiness" ? <>
            {!employee.ID ? <div className={styles.Loading}><Loading /></div> : <div className={styles.Assets}>
              <EmployeeHappiness EmployeeData={employee} />
            </div>}
          </> : Tab === "probation period" ? <>
            {employee.ID ? <>
              <Modal Show={addObjective} Close={() => { setAddObjective(false); setObjective(defaultObjective) }} Buttons={[{
                Text: "Cancel",
                OnClick: () => { setAddObjective(false); setObjective(defaultObjective) },
                Color: "Link"
              }, {
                Text: objective.ID ? <>
                  <i>
                    <FontAwesomeIcon icon={faSave} />
                  </i>
                  Save Task
                </> : <>
                  <i>
                    <FontAwesomeIcon icon={faPlus} />
                  </i>
                  Add Task
                </>,
                OnClick: () => SaveObjective(objective),
                Color: "Theme"
              }]} Size={'small'} Title={'Task'}>
                <p>Provide details about the task that needs to be acheived by the end of the employee's Probation Period ({new Date(employee.Business.Probation.ProbationEndDate).toDateString()})</p>
                <Input OnChange={(v) => setObjective({ ...objective, Title: v })} Placeholder={'...'} Type={'text'} Label={"Title"} Value={objective.Title} Required />
                <Input OnChange={(v) => setObjective({ ...objective, Description: v })} Placeholder={'...'} Type={'textarea'} Label={"Description"} Value={objective.Description} Required />
                <Input Type="checkbox" OnChange={(v) => setObjective({ ...objective, isComplete: v })} Placeholder={''} Label={'This objective has been achieved'} Value={objective.isComplete} />
              </Modal>

              <div className={`${styles.Panel} ${showObjectives ? styles.Show : ""}`}>
                <div className={styles.PanelInner}>
                  <button onClick={() => setShowObjectives(false)}>
                    <i>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </i>

                    Back
                  </button>

                  <h1>Probation Tasks</h1>
                  <p>Set some tasks that you expect this employee to achieve during their probation period. Tick off each objective once they have been completed.</p>

                  <ul>
                    {
                      employee.Business.Probation.Objectives.map((obj: Objective) => {
                        return <li className={obj.isComplete ? styles.Green : ""} onClick={() => { setObjective(obj); setAddObjective(true) }}>
                          <button className={styles.Check} onClick={(e) => { e.stopPropagation(); SaveObjective({ ...obj, isComplete: !obj.isComplete }) }}>
                            {obj.isComplete ? <i>
                              <FontAwesomeIcon icon={faCheckDouble} />
                            </i> : <></>}
                          </button>
                          <p>
                            {obj.Title}
                            <small>{obj.Description}</small>
                          </p>
                          <b style={{ backgroundColor: ObjectiveColours.find(o => o.Status === obj.Type)?.Colour }}>
                            <span style={{ color: ObjectiveColours.find(o => o.Status === obj.Type)?.Colour }}>{obj.Type}</span>
                          </b>
                        </li>
                      })
                    }

                    <li>
                      <button type="button" onClick={() => setAddObjective(true)}>
                        <i>
                          <FontAwesomeIcon icon={faPlus} />
                        </i>
                        Add a Task
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={`${styles.Panel} ${showReviewForm ? styles.Show : ""}`}>
                <div className={styles.PanelInner}>
                  <button onClick={() => setShowReviewForm(false)}>
                    <i>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </i>

                    Back
                  </button>

                  <h1>Probation Review</h1>
                  <p>This is to be completed by {employee.Personal.Forename} {employee.Personal.Surname}'s Line Manager at the end of their probation period.</p>

                  <div className={styles.MultiSelect}>
                    <div className={styles.MultiSelectHeader}>
                      <b>(Please Tick)</b>
                      <span>Improvement Required</span>
                      <span>Unsatisfactory</span>
                      <span>Satisfactory</span>
                      <span>Good</span>
                      <span>Excellent</span>
                    </div>
                    <div className={styles.MultiSelectItem}>
                      <b>Quality & Accuracy of work</b>

                      {rating.map((rate) => {
                        return <span>
                          <input type="radio" onChange={() => setReviewForm({ ...reviewForm, Quality: rate })} checked={reviewForm.Quality === rate} />
                        </span>
                      })}
                    </div>
                    <div className={styles.MultiSelectItem}>
                      <b>Job Knowledge </b>

                      {rating.map((rate) => {
                        return <span>
                          <input type="radio" onChange={() => setReviewForm({ ...reviewForm, Knowledge: rate })} checked={reviewForm.Knowledge === rate} />
                        </span>
                      })}
                    </div>
                    <div className={styles.MultiSelectItem}>
                      <b>Efficiency</b>

                      {rating.map((rate) => {
                        return <span>
                          <input type="radio" onChange={() => setReviewForm({ ...reviewForm, Effiency: rate })} checked={reviewForm.Effiency === rate} />
                        </span>
                      })}
                    </div>
                    <div className={styles.MultiSelectItem}>
                      <b>Attendance</b>

                      {rating.map((rate) => {
                        return <span>
                          <input type="radio" onChange={() => setReviewForm({ ...reviewForm, Attendance: rate })} checked={reviewForm.Attendance === rate} />
                        </span>
                      })}
                    </div>
                    <div className={styles.MultiSelectItem}>
                      <b>Time Keeping</b>

                      {rating.map((rate) => {
                        return <span>
                          <input type="radio" onChange={() => setReviewForm({ ...reviewForm, TimeKeeping: rate })} checked={reviewForm.TimeKeeping === rate} />
                        </span>
                      })}
                    </div>
                    <div className={styles.MultiSelectItem}>
                      <b>Work relationships (teamwork and interpersonal communication skills) </b>

                      {rating.map((rate) => {
                        return <span>
                          <input type="radio" onChange={() => setReviewForm({ ...reviewForm, Relationships: rate })} checked={reviewForm.Relationships === rate} />
                        </span>
                      })}
                    </div>

                    <div className={styles.MultiSelectItem}>
                      <b>Competency in the role </b>

                      {rating.map((rate) => {
                        return <span>
                          <input type="radio" onChange={() => setReviewForm({ ...reviewForm, Competency: rate })} checked={reviewForm.Competency === rate} />
                        </span>
                      })}
                    </div>
                  </div>

                  <Input Type="checkbox" OnChange={(v) => setReviewForm({ ...reviewForm, ObjectivesMet: v })} Placeholder={''} Label={'Have the objectives been met?'} Value={reviewForm.ObjectivesMet} />
                  {reviewForm.ObjectivesMet ? <></> : <Input Type="textarea" OnChange={(v) => setReviewForm({ ...reviewForm, ObjectivesAction: v })} Placeholder={''} Label={'If "No", please provide details of why the objectives were not met'} Value={reviewForm.ObjectivesAction} />}

                  <Input Type="checkbox" OnChange={(v) => setReviewForm({ ...reviewForm, Training: v })} Placeholder={''} Label={'Have the training and development needs been met during the probation period?'} Value={reviewForm.Training} />
                  {reviewForm.Training ? <></> : <Input Type="textarea" OnChange={(v) => setReviewForm({ ...reviewForm, TrainingAction: v })} Placeholder={''} Label={'If "No", please provide details'} Value={reviewForm.TrainingAction} />}

                  <Input Type="textarea" OnChange={(v) => setReviewForm({ ...reviewForm, Feedback: v })} Placeholder={'Outline the areas where employee is performing well against objectives and standards set'} Label={'Performance Feedback'} Value={reviewForm.Feedback} />

                  <Input Type="checkbox" OnChange={(v) => setReviewForm({ ...reviewForm, ProbationSuccess: v })} Placeholder={''} Label={'Has the probation period been successful and the employee put into post'} Value={reviewForm.ProbationSuccess} />
                  {reviewForm.ProbationSuccess ? <></> : <Input Type="textarea" OnChange={(v) => setReviewForm({ ...reviewForm, NoReason: v })} Placeholder={''} Label={'If "No", please give details '} Value={reviewForm.NoReason} />}

                  <Input Type="checkbox" OnChange={(v) => setReviewForm({ ...reviewForm, Extended: v })} Placeholder={''} Label={'Should the probation period be extended?'} Value={reviewForm.Extended} />

                  {reviewForm.Extended ? <>
                    <Input Type="textarea" OnChange={(v) => setReviewForm({ ...reviewForm, ExtendedReason: v })} Placeholder={''} Label={'If "Yes", detail areas of improvement'} Value={reviewForm.ExtendedReason} />
                    <Input Type="date" OnChange={(v) => setReviewForm({ ...reviewForm, NewEndDate: v })} Placeholder={''} Label={'New probation End Date'} Value={reviewForm.NewEndDate} />
                  </> : <></>}

                  <div className={styles.PanelSave}>
                    <Button OnClick={() => SendReview(reviewForm)}>
                      <i>
                        <FontAwesomeIcon icon={faCheckDouble} />
                      </i>

                      Submit Review
                    </Button>
                  </div>
                </div>
              </div>

              <form onSubmit={(e) => e.preventDefault()} autoComplete="off" className={styles.Form}>
                <div className={styles.FormInner}>
                  <h1>Probation Period</h1>
                  <p>The probation period is a period of length between starting the role prior to any benefits being released to the Employee. As a Manager or Team Leader, you will be expected to assess the Employee during this period.</p>

                  {[0, 3, 6, 9].includes(employee.Business.Probation.ProbationLength) ? <></> : <Input Disabled={true} Type="number" Value={employee.Business.Probation.ProbationLength === -1 ? "0" : employee.Business.Probation.ProbationLength.toString()} OnChange={(v) => setEmployee({ ...employee, Business: { ...employee.Business, Probation: { ...employee.Business.Probation, ProbationLength: parseInt(v) } } })} Placeholder="..." Label="Probation Period Length (months)" />}

                  <Input Disabled={true} Width="half" Type="date" Value={new Date(employee.Business.Probation.ProbationStartDate).toDateString()} OnChange={(v) => { }} Placeholder="..." Label="Probation Period Start Date" />

                  {employee.Business.Probation.ReviewForm && employee.Business.Probation.ReviewForm.Extended ? <Input Disabled={true} Width="half" Type="date" Value={new Date(employee.Business.Probation.ReviewForm.NewEndDate).toDateString()} OnChange={(v) => { }} Placeholder="..." Label="Probation Period End Date" /> : <Input Disabled={true} Width="half" Type="date" Value={new Date(employee.Business.Probation.ProbationEndDate).toDateString()} OnChange={(v) => { }} Placeholder="..." Label="Probation Period End Date" />}

                  {
                    User.PackageID >= 2 ? <>
                      <hr />

                      <div className={styles.Task} onClick={() => setShowObjectives(true)}>
                        <h3>
                          Probation Tasks
                          <small>Set what you expect {employee.Personal.Forename} {employee.Personal.Surname} to acheive during their Probation Period</small>
                        </h3>
                        <span>
                          {employee.Business.Probation.Objectives.filter(o => o.isComplete).length} out of {employee.Business.Probation.Objectives.length} Complete
                        </span>
                        <i className={employee.Business.Probation.Objectives.filter(o => o.isComplete).length === employee.Business.Probation.Objectives.length ? styles.Green : ""}>
                          <FontAwesomeIcon icon={employee.Business.Probation.Objectives.filter(o => o.isComplete).length === employee.Business.Probation.Objectives.length ? faCheckCircle : faChevronRight} />
                        </i>
                      </div>

                      {/* <div className={styles.Task}>
                        <h3>
                          Invite to a Probation Review Meeting
                          <small>This will send an email to {Employee.Business.Email} inviting them to a meeting to discuss how their probation period has gone</small>
                        </h3>
                        <i>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </i>
                      </div> */}

                      <div className={styles.Task} onClick={() => { setShowReviewForm(true); setReviewForm(employee.Business.Probation.ReviewForm && employee.Business.Probation.ReviewForm.ID ? employee.Business.Probation.ReviewForm : defaultReviewForm) }}>
                        <h3>
                          Probation Review Form
                          <small>Start your probation review for {employee.Personal.Forename} {employee.Personal.Surname} by completing this form</small>
                        </h3>
                        <i className={employee.Business.Probation.ReviewForm && employee.Business.Probation.ReviewForm.ID ? styles.Green : ""}>
                          <FontAwesomeIcon icon={employee.Business.Probation.ReviewForm && employee.Business.Probation.ReviewForm.ID ? faCheckCircle : faChevronRight} />
                        </i>
                      </div>

                      {/* <div className={styles.Task}>
                        <h3>
                          Send Successful Probation Letter
                          <small>Send a letter confirming that their probation was successful and they are now able to access all the company benefits they are eligible for.</small>
                        </h3>
                        <i>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </i>
                      </div> */}
                    </> : <></>
                  }
                </div>
              </form>
            </> : <></>}
          </> : Tab === "worklog" && employee.ID ? <div className={styles.Worklog}>
            <EmployeePortalWorklog UseEmployee={employee} />
          </div> : Tab === "leave requests" && employee.ID ? <div className={styles.HolidayRequests}>
            <EmployeeHolidayRequests Employee={employee} RefID={RefID} />
          </div> : <div className={styles.Loading}><Loading /></div>}
        </div>
      </div>
    </div>
  </>);
};

export default EmployeePortalEmployee;
