// extracted by mini-css-extract-plugin
export var Active = "Employee-module--Active--28139";
export var Address = "Employee-module--Address--62fdc";
export var Assets = "Employee-module--Assets--d265d";
export var Body = "Employee-module--Body--6218b";
export var Buttons = "Employee-module--Buttons--0a885";
export var ButtonsInner = "Employee-module--ButtonsInner--b26a9";
export var Calendar = "Employee-module--Calendar--29cd8";
export var Check = "Employee-module--Check--11d2b";
export var Checkbox = "Employee-module--Checkbox--ca2f7";
export var CheckboxInner = "Employee-module--CheckboxInner--b3e38";
export var CheckboxInnerSlider = "Employee-module--CheckboxInnerSlider--93d2e";
export var Contacts = "Employee-module--Contacts--b7fa7";
export var ContactsItem = "Employee-module--ContactsItem--714b0";
export var Content = "Employee-module--Content--87247";
export var Delete = "Employee-module--Delete--388a6";
export var Error = "Employee-module--Error--d0ec8";
export var Form = "Employee-module--Form--31d45";
export var FormHeader = "Employee-module--FormHeader--5d5fe";
export var FormInner = "Employee-module--FormInner--03a22";
export var Green = "Employee-module--Green--93de2";
export var HalfBlock = "Employee-module--HalfBlock--d9a69";
export var HolidayRequests = "Employee-module--HolidayRequests--c92ce";
export var Journal = "Employee-module--Journal--82066";
export var JournalContent = "Employee-module--JournalContent--f74bd";
export var JournalHeader = "Employee-module--JournalHeader--187a4";
export var JournalHeaderLabel = "Employee-module--JournalHeaderLabel--6950f";
export var JournalInner = "Employee-module--JournalInner--958cf";
export var JournalLine = "Employee-module--JournalLine--fbdbe";
export var JournalLineDot = "Employee-module--JournalLineDot--e3aa2";
export var Link = "Employee-module--Link--34555";
export var Loading = "Employee-module--Loading--f13f7";
export var MultiSelect = "Employee-module--MultiSelect--3a1be";
export var MultiSelectHeader = "Employee-module--MultiSelectHeader--a05a6";
export var MultiSelectItem = "Employee-module--MultiSelectItem--b05ad";
export var Notices = "Employee-module--Notices--a2b26";
export var NoticesInner = "Employee-module--NoticesInner--464c6";
export var NoticesItem = "Employee-module--NoticesItem--458d1";
export var Panel = "Employee-module--Panel--75ed5";
export var PanelInner = "Employee-module--PanelInner--527dc";
export var PanelSave = "Employee-module--PanelSave--661ac";
export var Picture = "Employee-module--Picture--f967d";
export var QuickSet = "Employee-module--QuickSet--8fa09";
export var QuickSetDropdown = "Employee-module--QuickSetDropdown--a3144";
export var QuickSetList = "Employee-module--QuickSetList--19da7";
export var QuickSetListItem = "Employee-module--QuickSetListItem--23288";
export var Show = "Employee-module--Show--1d4ff";
export var SidePanel = "Employee-module--SidePanel--d77f0";
export var Tabs = "Employee-module--Tabs--183b5";
export var TabsIcons = "Employee-module--TabsIcons--c4103";
export var Task = "Employee-module--Task--6a0c2";
export var User = "Employee-module--User--17d3a";
export var Worklog = "Employee-module--Worklog--8fc2f";