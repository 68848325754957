import React, { useContext, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import * as styles from './Policy.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faChevronLeft, faChevronRight, faSignature, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { EmployeeContext, UserContext } from '../../../../Context/UserContext';
import { Document, Policy, SignedPolicy } from '../../../../Classes/Document';
import { API_GetPolicy, API_GetPolicyThumbnail, API_GetThumbnail, API_SignPolicy } from '../../../../Services/ApiRoutes';
import { Fetch } from '../../../../Services/Fetch';
import { DecryptValue } from '../../../../Services/Crypto';
import { Loading } from '../../../../components/Loading/Loading';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { Modal } from '../../../../components/Modal/Modal';

const EmployeePolicy: React.FC = (props: any) => {
  const ID: string = props.ID ? props.ID : "";
  const { User } = useContext(UserContext);
  const { Employee } = useContext(EmployeeContext);
  const [doc, setDocument] = useState<Policy>({} as Policy);
  const [thumbnail, setThumbnail] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [Show, setShow] = useState<boolean>(false);
  const [sigPad, setSigPad] = useState(null);

  useEffect(() => {
    if (ID && User.ID)
      GetData();
  }, [ID, User])

  useEffect(() => {
    if (!Show && sigPad)
      (sigPad as any).clear();
  }, [Show])

  const GetData = async () => {
    await Fetch(`${API_GetPolicy}`, parseInt(DecryptValue(ID))).then((Response: Policy) => {
      GetThumbnail(Response);
    });
  }

  const GetThumbnail = async (PolicyData: Policy) => {
    if (PolicyData.isCustom)
      await Fetch(`${API_GetPolicyThumbnail}`, PolicyData).then((thumbnail: string[]) => {
        setDocument({ ...PolicyData, Thumbnails: thumbnail.filter(t => t !== "") });
      })
    else
      await Fetch(`${API_GetThumbnail}?Preview=false`, {
        ID: parseInt(PolicyData.Document.split("/")[2]),
        FileTitle: PolicyData.Document.split("/")[3],
        Parameters: JSON.parse(PolicyData.DataJSON),
        Logo: User.Logo,
        Company: User.Company
      } as Document).then((thumbnail: string[]) => {
        setDocument({ ...PolicyData, Thumbnails: thumbnail ? thumbnail.filter(t => t !== "") : [] });
      })

    setThumbnail(0);
  }

  const Submit = async () => {
    setLoading(true);

    let signed: SignedPolicy = {
      ID: 0,
      CompanyID: Employee.CompanyID,
      EmployeeID: User.ID,
      PolicyID: doc.ID,
      Signed: doc.isCustom ? "TRUE" : sigPad.getTrimmedCanvas().toDataURL('image/png'),
      SignedOn: new Date().toISOString()
    } as SignedPolicy

    await Fetch(`${API_SignPolicy}`, signed).then((Signed: SignedPolicy) => {
      setShow(false);
      setDocument({ ...doc, Signed: Signed });
    })

    setLoading(false);
  }

  return (<>
    <div className={styles.Pages}>
      {thumbnail >= 0 ? <>
        <h1>{doc.Title}</h1>
        {doc.Signed.ID ? <div className={styles.Completed}>
          <i>
            <FontAwesomeIcon icon={faCheckCircle} />
          </i>
          <p>
            <b>Policy {doc.isCustom ? "Agreed" : "Signed"}</b>
            <span>You have {doc.isCustom ? "agreed to" : "signed"} this Policy, acknowledging you understand it</span>
          </p>
        </div> :
          <p>Please read this policy thoroughly before signing at the end. To sign the document, you must be on the last page of the policy.</p>}
        <hr />

        <div className={styles.PagesInner}>
          {
            doc.Thumbnails.map((thumb, i) => {
              return <div className={`${styles.Page} ${thumbnail === i ? styles.Active : ""}`}>
                <img loading={"lazy"} src={`data:image/png;base64,${thumb}`} />

                {(doc.Thumbnails.length - 1) === thumbnail && !doc.Signed.ID ? <div className={styles.Button}>
                  <button onClick={() => { doc.isCustom ? Submit() : setShow(true); }}>
                    <i>
                      <FontAwesomeIcon icon={doc.isCustom ? faCheckDouble : faSignature} />
                    </i>
                    {doc.isCustom ? "Agree to Policy" : "Sign Document"}
                  </button>
                </div> : <></>}
              </div>
            })
          }

          <div className={styles.PagesControls}>
            <button disabled={thumbnail === 0} onClick={() => setThumbnail(thumbnail - 1)}>
              <i>
                <FontAwesomeIcon icon={faChevronLeft} />
              </i>
            </button>
            <button disabled={thumbnail === (doc.Thumbnails.length - 1)} onClick={() => setThumbnail(thumbnail + 1)}>
              <i>
                <FontAwesomeIcon icon={faChevronRight} />
              </i>
            </button>
          </div>
        </div>
      </> : <div className={styles.Loading}><Loading /></div>}
    </div>


    <Modal Show={Show} Close={() => setShow(false)} Buttons={[{
      Color: "Link",
      OnClick: () => setShow(false),
      Text: "Cancel"
    },
    {
      Color: "Link",
      OnClick: () => Submit(),
      Text: loading ? <><i><FontAwesomeIcon icon={faSpinner} spin={true} /></i>Submitting...</> : "Sign & Submit",
      Disabled: loading
    }]} Size={'small'} Title={'Sign Policy'}>
      <div className={styles.Signature}>
        <SignatureCanvas ref={(ref) => setSigPad(ref)} penColor='black'
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} />
      </div>
      <button onClick={() => (sigPad as any).clear()}>Clear</button>
    </Modal>
  </>);
};

export default EmployeePolicy;
