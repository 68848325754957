import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Employee.module.scss';
import * as general from '../Styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronRight, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { EmergencyContact } from '../../../Classes/Employee';
import { Fetch } from '../../../Services/Fetch';
import { API_RemoveEmployeeContact, API_SaveEmployeeContact } from '../../../Services/ApiRoutes';
import { Loading } from '../../../components/Loading/Loading';
import Input from '../../../components/Input/input';
import { EmployeeContext, UserContext } from '../../../Context/UserContext';
import { Modal } from '../../../components/Modal/Modal';
import { EmployeeAccountTabs } from './Account';
import { Button } from '../../Portal/Courses/Components/Button/Button';

const EmployeePortalContacts: React.FC = () => {
  const { User, Language } = useContext(UserContext);
  const { Employee, setEmployee } = useContext(EmployeeContext);
  const [emergencyContact, setEmergencyContact] = useState<EmergencyContact>({} as EmergencyContact);
  const [newContact, setNewContact] = useState<EmergencyContact>({} as EmergencyContact);
  const [emergencyContactError, setEmergencyContactError] = useState<string>("");
  const [showModel, setShowModel] = useState<boolean>(false);
  const [showNew, setShowNew] = useState<boolean>(false);

  useEffect(() => {
    if (Employee.ID) {
      let total: number = 0;

      if (Employee.Business.CurrentEmployment.WorkDays)
        JSON.parse(Employee.Business.CurrentEmployment.WorkDays).map(d => {
          total += d.Hours ? d.Hours : 0;
        })

      total = parseFloat(total.toFixed(1));
      setEmployee({ ...Employee, Business: { ...Employee.Business, CurrentEmployment: { ...Employee.Business.CurrentEmployment, ContractedHours: total.toFixed(1) } } });
    }
  }, [Employee.Business && Employee.Business.CurrentEmployment.WorkDays]);

  const SaveEmergencyContact = async (e: any, contact: EmergencyContact) => {
    if (e)
      e.preventDefault();
    contact.EmployeeID = User.ID;
    if (!contact.ID)
      contact.ID = 0;

    if (contact.Forename && contact.Surname && contact.Phone && contact.Relationship) {
      await Fetch(`${API_SaveEmployeeContact}`, contact).then((emp: EmergencyContact) => {
        if (emp.ID) {
          setEmergencyContact(emp);
          let contacts: EmergencyContact[] = JSON.parse(JSON.stringify(Employee.EmergencyContacts));

          if (contact.ID === 0)
            contacts.push(emp);
          else {
            contacts = contacts.map(c => {
              if (c.ID === emp.ID)
                return emp;
              return c;
            })
          }

          setEmployee({ ...Employee, EmergencyContacts: contacts });
          setNewContact({} as EmergencyContact);
          setShowNew(false);
          setEmergencyContactError("");
        }
      });
    } else
      setEmergencyContactError("Please fill in all required fields");
  }

  const SaveNewContact = () => {
    if (newContact.Forename && newContact.Surname && newContact.Phone && newContact.Relationship) {
      SaveEmergencyContact(undefined, newContact);
    } else
      setEmergencyContactError("Please fill in all required fields");
  }

  const RemoveEmergencyContact = async () => {
    let contact: EmergencyContact = JSON.parse(JSON.stringify(emergencyContact));

    await Fetch(`${API_RemoveEmployeeContact}`, contact.ID).then((success: boolean) => {
      if (success) {
        setEmergencyContact({
          ID: 0,
          Forename: "",
          Surname: "",
          Phone: "",
          Relationship: "",
          Email: ""
        } as EmergencyContact);
        let contacts: EmergencyContact[] = JSON.parse(JSON.stringify(Employee.EmergencyContacts));
        let index: number = contacts.findIndex(c => c.ID === contact.ID);

        if (index >= 0) {
          contacts.splice(index, 1);
        }

        setEmployee({ ...Employee, EmergencyContacts: contacts });
        setShowModel(false);
      }
    });
  }

  return (<>
    <Modal Show={showModel} Close={() => setShowModel(false)} Buttons={[{
      Text: "No, Keep Contact",
      OnClick: () => setShowModel(false),
      Color: "Link"
    }, {
      Text: "Yes, Remove Contact",
      OnClick: () => RemoveEmergencyContact(),
      Color: "red"
    }]} Size={'small'} Title={'Delete Emergency Contact'}>
      {emergencyContact.ID ? <p>Are you sure you want to remove {emergencyContact.Forename} {emergencyContact.Surname} from your Contacts? </p> : <></>}
    </Modal>

    <Modal Show={showNew} Close={() => { setShowNew(false); setNewContact({} as EmergencyContact) }} Buttons={[{
      Text: "Cancel",
      OnClick: () => { setShowNew(false); setNewContact({} as EmergencyContact) },
      Color: "Link"
    }, {
      Text: "Add Contact",
      OnClick: () => SaveNewContact(),
      Color: "Theme",
      Disabled: !newContact.Forename || !newContact.Surname || !newContact.Phone || !newContact.Relationship
    }]} Size={'small'} Title={'Emergency Contact'}>
      <p>Add a new Emergency Contact</p>
      <Input Required Width="half" Type="text" Value={newContact.Forename} OnChange={(v) => setNewContact({ ...newContact, Forename: v })} Placeholder="..." Label="Forename" />
      <Input Required Width="half" Type="text" Value={newContact.Surname} OnChange={(v) => setNewContact({ ...newContact, Surname: v })} Placeholder="..." Label="Surname" />
      <Input Type="email" Value={newContact.Email} OnChange={(v) => setNewContact({ ...newContact, Email: v })} Placeholder="..." Label="Email" />
      <Input Required Width="half" Type="text" Value={newContact.Phone} OnChange={(v) => setNewContact({ ...newContact, Phone: v })} Placeholder="..." Label="Phone" />
      <Input Required Width="half" Type="text" Value={newContact.Relationship} OnChange={(v) => setNewContact({ ...newContact, Relationship: v })} Placeholder="..." Label="Relationship" />
    </Modal>

    <div className={general.Main}>
      <div className={styles.Body}>
        <EmployeeAccountTabs {...Employee} />

        <div className={styles.Body}>
          <div className={styles.Content}>
            {Employee.ID ? <>
              <div className={styles.Contacts}>
                <h2>
                  <span>Contacts</span>
                  <button onClick={() => setShowNew(true)}>
                    <i>
                      <FontAwesomeIcon icon={faPlus} />
                    </i>
                    New Contact
                  </button>
                </h2>
                {Employee.EmergencyContacts && Employee.EmergencyContacts.length ?
                  Employee.EmergencyContacts.map((contact: EmergencyContact) => {
                    return <div onClick={() => setEmergencyContact(contact)} className={`${styles.ContactsItem} ${contact.ID === emergencyContact.ID ? styles.Active : ""}`}>
                      <i>
                        <FontAwesomeIcon icon={emergencyContact.ID === contact.ID ? faCheckCircle : faCircle} />
                      </i>
                      <h3>
                        <span>{contact.Forename} {contact.Surname}</span>
                        <small>{contact.Relationship}</small>
                      </h3>
                      <i>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </i>
                    </div>
                  }) : <div className={styles.Message}>
                    <i>
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </i>
                    <h1>You Have No {Language.Application_Employees.EmergencyContacts} Assigned</h1>
                  </div>}
              </div>
            </> : <Loading />}

            <form autoComplete="off" className={styles.Form} onSubmit={(e) => SaveEmergencyContact(e, emergencyContact)}>
              {emergencyContact.ID ? <div className={styles.FormInner}>
                <div className={styles.Contact}>
                  <h1>{Language.Application_Employees.EmergencyContacts}</h1>
                  <p>{emergencyContact.ID ? "Edit an existing Emergency Contact" : "Add a new Emergency Contact"}</p>
                  <Input Required Width="half" Type="text" Value={emergencyContact.Forename} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Forename: v })} Placeholder="..." Label="Forename" />
                  <Input Required Width="half" Type="text" Value={emergencyContact.Surname} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Surname: v })} Placeholder="..." Label="Surname" />
                  <Input Type="email" Value={emergencyContact.Email} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Email: v })} Placeholder="..." Label="Email" />
                  <Input Required Width="half" Type="text" Value={emergencyContact.Phone} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Phone: v })} Placeholder="..." Label="Phone" />
                  <Input Required Width="half" Type="text" Value={emergencyContact.Relationship} OnChange={(v) => setEmergencyContact({ ...emergencyContact, Relationship: v })} Placeholder="..." Label="Relationship" />
                  <p className={styles.HalfBlock}>e.g.Mother, Father, Spouse / Partner</p>

                  {emergencyContactError ? <div className={styles.Error}>
                    {emergencyContactError}
                  </div> : <></>}

                  <div className={styles.Buttons}>
                    {emergencyContact.ID ? <button className={`${styles.Link} ${styles.Delete}`} type="button" onClick={() => setShowModel(true)}>
                      Delete Contact
                    </button> : <></>}

                    <div className={styles.ButtonsInner}>
                      {emergencyContact.ID ? <button className={styles.Link} type="button" onClick={() => setEmergencyContact({
                        ID: 0,
                        Forename: "",
                        Surname: "",
                        Phone: "",
                        Relationship: "",
                        Email: ""
                      } as EmergencyContact)}>
                        Cancel
                      </button> : <></>}
                      <button>
                        <i>
                          <FontAwesomeIcon icon={faSave} />
                        </i>
                        Save Contact
                      </button>
                    </div>
                  </div>
                </div>
              </div> : <div className={styles.Message}>
                <i>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </i>
                <h1>No Emergency Contact Selected</h1>
                <p>Select an Emergency Contact from the side or create a new Contact by clicking "+ New Contact"</p>
                <Button Type="button" Color={"Theme"} OnClick={() => setShowNew(true)}>
                  <i>
                    <FontAwesomeIcon icon={faPlus} />
                  </i>
                  New Contact
                </Button>
              </div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default EmployeePortalContacts;
