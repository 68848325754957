// extracted by mini-css-extract-plugin
export var Active = "Policy-module--Active--2f1d3";
export var Button = "Policy-module--Button--a4a6d";
export var Completed = "Policy-module--Completed--503b6";
export var Loading = "Policy-module--Loading--d4218";
export var Modal = "Policy-module--Modal--df0ae";
export var ModalBackground = "Policy-module--ModalBackground--f6f6a";
export var ModalContent = "Policy-module--ModalContent--136e0";
export var ModalFooter = "Policy-module--ModalFooter--0ac99";
export var ModalHeader = "Policy-module--ModalHeader--c3556";
export var Page = "Policy-module--Page--2a550";
export var Pages = "Policy-module--Pages--cfe24";
export var PagesControls = "Policy-module--PagesControls--3fbc3";
export var PagesInner = "Policy-module--PagesInner--f5fbf";
export var Show = "Policy-module--Show--72eb2";
export var Signature = "Policy-module--Signature--2320c";