import React, { useContext, useEffect, useState } from 'react';
import * as styles from './Department.module.scss';
import * as general from '../Styles.module.scss';
import { OrganisationContext, UserContext } from '../../../Context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faQuestionCircle, faShare, faTasks, } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../../components/Loading/Loading';
import { Employee, CalendarEvent, Department, CalendarSettings, Objective, HappinessLog, LieuDay, CarryOver } from '../../../Classes/Employee';
import { EmploymentStatus, StatusColour, StatusColours } from '../../../Enums/EmploymentStatus';
import { EmployeeBlock } from '../../Portal/Employees/Employees';
import { Link, navigate } from 'gatsby';
import { DecryptValue, EncryptValue } from '../../../Services/Crypto';
import PortalEmployeeScatterCalendar from '../../Portal/Employees/Employee/ScatterCalendar/ScatterCalendar';
import PortalTaskManager from '../../Portal/Employees/TaskManager/TaskManager';
import PortalMoodMonitor from '../../Portal/MoodMonitor/MoodMonitor';
import { faFaceGrinBeam } from '@fortawesome/free-regular-svg-icons';
import { TaskStatus } from '../../../Classes/User';

const EmployeePortalDepartment: React.FC = (props: any) => {
  const RefID: string = props.RefID ? props.RefID : "";
  const { User, Language } = useContext(UserContext);
  const { Organisation } = useContext(OrganisationContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [employees, setEmployees] = useState<any[]>([] as any[]);
  const [show, setShow] = useState<number>(0);
  const [happiness, setHappiness] = useState<number>(0);
  const months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  useEffect(() => {
    if (User.ID)
      getDepartments();
  }, [User])

  const getDepartments = () => {
    setLoading(true);
    let departmentData: any[] = [];
    let deps: Department[] = Organisation.Departments.filter(t => t.Manager.ID === User.ID);

    deps.map((dep: Department) => {
      const depEmps: Employee[] = GetEmployees(dep);

      if (!departmentData.find((d: any) => d.department.ID === dep.ID))
        departmentData.push({
          department: dep,
          employees: depEmps
        })

      departmentData = getSubDepartments(dep.ID, departmentData);
    });

    if (departmentData.length === 1) {
      navigate(`/Employee/Portal/Departments/${EncryptValue(departmentData[0].ID.toString())}`);
    }

    setEmployees(departmentData);
    setLoading(false);
  }

  const getSubDepartments = (DepartmentID: number, Data: any[]) => {
    Organisation.Departments.filter((Department: Department) => Department.Parent === DepartmentID).map((Department: Department) => {
      const emps: Employee[] = GetEmployees(Department);

      if (!Data.find((dep: any) => dep.department.ID === Department.ID))
        Data.push({
          department: Department,
          employees: emps
        });

      if (Organisation.Departments.find((dep: Department) => dep.Parent === Department.ID))
        Data = getSubDepartments(Department.ID, Data);
    });

    return Data;
  }

  const GetEmployees = (Department: Department) => {
    return Organisation.Employees.filter((e: Employee) => User.ID !== e.ID && e.Business.Department.ID === Department.ID);
  }

  const GetUpdates = (empDep: any) => {
    const today: Date = new Date();
    const startOfWeek: Date = new Date(new Date(today).setDate(new Date(today).getDate() - (new Date(today).getDay() ? (new Date(today).getDay() - 1) : 6)));
    const endOfWeek: Date = new Date(new Date(startOfWeek).setDate(new Date(startOfWeek).getDate() + 6));

    return [{
      Title: `${months[today.getMonth()]}'s Birthdays`,
      Type: "EMPLOYEES",
      Data: empDep.employees.filter((emp: Employee) => (emp.Personal && emp.Personal.DateOfBirth && new Date(emp.Personal.DateOfBirth).getMonth() === today.getMonth())),
      Error: `No Birthdays in ${months[today.getMonth()]}`,
      Width: "Half"
    }, {
      Title: `Employee's Moods`,
      Type: "MOODS",
      Data: empDep.employees.filter((employee: Employee) => employee.Account.HappinessLog && (JSON.parse(employee.Account.HappinessLog) as HappinessLog[]).find((log: HappinessLog) => new Date(log.Date).toDateString() === today.toDateString())),
      Error: `No Employee Moods`,
      Width: "Half"
    }, {
      Title: `Today's Absences`,
      Type: "EVENTS",
      Data: empDep.employees.filter((emp: Employee) => emp.CalendarEvents && emp.CalendarEvents.find((event: CalendarEvent) => event.Settings && (JSON.parse(event.Settings) as CalendarSettings[]).find((setting: CalendarSettings) => new Date(setting.Day).toDateString() === today.toDateString()))).map((emp: Employee) => {
        return { ...emp, CalendarEvents: emp.CalendarEvents.filter((event: CalendarEvent) => event.Settings && (JSON.parse(event.Settings) as CalendarSettings[]).find((setting: CalendarSettings) => new Date(setting.Day).toDateString() === today.toDateString())) }
      }),
      Error: `No Absences Today`,
      Width: "Half"
    }, {
      Title: `This Week's Absences`,
      Type: "EVENTS",
      Data: empDep.employees.filter((emp: Employee) => emp.CalendarEvents && emp.CalendarEvents.find((event: CalendarEvent) => event.Settings && (JSON.parse(event.Settings) as CalendarSettings[]).find((setting: CalendarSettings) => +endOfWeek >= +new Date(setting.Day) && +startOfWeek <= +new Date(setting.Day)))).map((emp: Employee) => {
        return { ...emp, CalendarEvents: emp.CalendarEvents.filter((event: CalendarEvent) => event.Settings && (JSON.parse(event.Settings) as CalendarSettings[]).find((setting: CalendarSettings) => +endOfWeek >= +new Date(setting.Day) && +startOfWeek <= +new Date(setting.Day))) }
      }),
      Error: `No Absences This Week`,
      Width: "Half"
    }, {
      Title: `${months[today.getMonth()]}'s Absences`,
      Type: "EVENTS",
      Data: empDep.employees.filter((emp: Employee) => emp.CalendarEvents && emp.CalendarEvents.find((event: CalendarEvent) => StatusColours.find((status: StatusColour) => status.Status === event.Type && status.canEmployee) && event.Settings && (JSON.parse(event.Settings) as CalendarSettings[]).find((setting: CalendarSettings) => new Date(setting.Day).getFullYear() === today.getFullYear() && new Date(setting.Day).getMonth() === today.getMonth()))).map((emp: Employee) => {
        return { ...emp, CalendarEvents: emp.CalendarEvents.filter((event: CalendarEvent) => StatusColours.find((status: StatusColour) => status.Status === event.Type && status.canEmployee) && event.Settings && (JSON.parse(event.Settings) as CalendarSettings[]).find((setting: CalendarSettings) => new Date(setting.Day).getFullYear() === today.getFullYear() && new Date(setting.Day).getMonth() === today.getMonth())) }
      }),
      Error: `No Absences in ${months[today.getMonth()]}`,
      Width: "Half"
    }, {
      Title: `Unapproved Absences`,
      Type: "EVENTS",
      Data: empDep.employees.filter((emp: Employee) => emp.CalendarEvents && emp.CalendarEvents.find((event: CalendarEvent) => event.isApproved === 0 && StatusColours.find((status: StatusColour) => status.Status === event.Type && status.canEmployee))),
      Error: `No Unapproved Absences`,
      Width: "Half"
    }, {
      Title: `${months[today.getMonth()]}'s Completed Assigned Tasks`,
      Type: "TASKS",
      Data: Organisation.Objectives.filter((objective: Objective) => User.TaskStatusOptionsData[User.TaskStatusOptionsData.length - 1].Name === objective.Status && Organisation.Employees.find((emp: Employee) => emp.Business.Department.ID === empDep.department.ID && objective.EmployeeID === emp.ID)),
      Error: `No Completed Tasks in ${months[today.getMonth()]}`,
      Width: "Half"
    }, {
      Title: `Unfinished Tasks`,
      Type: "TASKS",
      Data: Organisation.Objectives.filter((objective: Objective) => User.TaskStatusOptionsData[User.TaskStatusOptionsData.length - 1].Name !== objective.Status && Organisation.Employees.find((emp: Employee) => emp.Business.Department.ID === empDep.department.ID && objective.EmployeeID === emp.ID)),
      Error: `No Unfinished Tasks`,
      Width: "Half"
    }, {
      Title: `Time in Lieu`,
      Type: "LIEU",
      Data: Organisation.Employees.filter((emp: Employee) => emp.Business.Department.ID === empDep.department.ID && emp.Business.HolidayEntitlement.LieuDays && (JSON.parse(emp.Business.HolidayEntitlement.LieuDays) as LieuDay[])),
      Error: `No Time in Lieu`,
      Width: "Half"
    }, {
      Title: `Annual Leave`,
      Type: "HOLIDAY",
      Data: Organisation.Employees.filter((emp: Employee) => emp.Business.Department.ID === empDep.department.ID && emp.Business.HolidayEntitlement.LieuDays && (JSON.parse(emp.Business.HolidayEntitlement.LieuDays) as LieuDay[])),
      Error: `No Annual Leave`,
      Width: "Half"
    }, {
      Title: `Employees`,
      Type: "EMPLOYEES_BLOCK",
      Data: empDep.employees,
      Error: `No Employees`,
      Width: "Full"
    }];
  }

  const getTime = (event: CalendarEvent) => {
    let count: number = 0;
    const employee: Employee | undefined = Organisation.Employees.find((emp: Employee) => emp.ID === event.EmployeeID);
    if (employee) {
      const isHours: boolean = employee.Business.HolidayEntitlement.TimeOffUnit === "Hours";

      if (event.SettingsJSON) {
        (JSON.parse(event.Settings) as CalendarSettings[]).map((setting: CalendarSettings) => {
          count += isHours ? (setting.Hours ? setting.Hours : 0) : (setting.isHalfDay ? .5 : 1);
        })
      }

      return ` (${count} ${isHours ? "Hour(s)" : "Day(s)"})`;
    }

    return "";
  }

  const getTotalDays = (employeeData: Employee | undefined) => {
    if (employeeData)
      return parseInt(employeeData.Business.HolidayEntitlement.StatutoryDays ? employeeData.Business.HolidayEntitlement.StatutoryDays : "0") + getCarryOverDays(employeeData) + parseInt(employeeData.Business.HolidayEntitlement.ExtraDays ? employeeData.Business.HolidayEntitlement.ExtraDays : "0");
    return 0;
  }

  const getCarryOverDays = (employeeData: Employee | undefined) => {
    if (employeeData) {
      const carryOverData: CarryOver[] = JSON.parse(employeeData.Business.HolidayEntitlement.CarryOver ? employeeData.Business.HolidayEntitlement.CarryOver : '[]');
      const currentCarryOver: CarryOver | undefined = carryOverData.find((co: CarryOver) => parseInt(co.Year) === new Date().getFullYear());
      return currentCarryOver ? parseFloat(currentCarryOver.Time) : 0;
    }
    return 0;
  }

  const GetUsedCount = (employeeData: Employee | undefined, Status: number) => {
    var count = 0;
    let annualLeave: string = Object.keys(EmploymentStatus)[Object.values(EmploymentStatus).findIndex(e => e === EmploymentStatus.ANNUAL_LEAVE)];
    if (employeeData)
      employeeData.CalendarEvents.filter(e => e.SettingsJSON && e.SettingsJSON.filter(s => new Date(s.Day).getFullYear() === new Date().getFullYear()).length && e.Type === annualLeave && e.isApproved === Status).map(e => {
        e.SettingsJSON.filter(s => new Date(s.Day).getFullYear() === new Date().getFullYear()).map(s => {
          if (!Organisation.BankHolidays.find(b => b.Days.find(x => +new Date(x.date) === +new Date(s.Day))))
            count -= employeeData.Business.HolidayEntitlement.TimeOffUnit === "Days" ? (s.isHalfDay ? .5 : 1) : (s.Hours ? s.Hours : 0);
          else
            if (employeeData.Business.CurrentEmployment.worksBankHolidays)
              count -= employeeData.Business.HolidayEntitlement.TimeOffUnit === "Days" ? (s.isHalfDay ? .5 : 1) : (s.Hours ? s.Hours : 0);
        })
      })
    return count;
  }

  const getInLieuTime = (employeeData: Employee | undefined) => {
    let count: number = 0;

    if (employeeData)
      JSON.parse(employeeData.Business.HolidayEntitlement.LieuDays ? employeeData.Business.HolidayEntitlement.LieuDays : "[]").map((lieu: LieuDay) => {
        if (new Date(lieu.Date).getFullYear() === new Date().getFullYear())
          count += parseInt(lieu.Time);
      });

    return count;
  }

  const getUsedInLieuTime = (employeeData: Employee | undefined, Status: number) => {
    var count = 0;
    let annualLeave: string = Object.keys(EmploymentStatus)[Object.values(EmploymentStatus).findIndex(e => e === EmploymentStatus.TIME_IN_LIEU)];
    if (employeeData)
      employeeData.CalendarEvents.filter(e => e.SettingsJSON && e.SettingsJSON.filter(s => new Date(s.Day).getFullYear() === new Date().getFullYear()).length && e.Type === annualLeave && e.isApproved === Status).map(e => {
        e.SettingsJSON.filter(s => new Date(s.Day).getFullYear() === new Date().getFullYear()).map(s => {
          if (!Organisation.BankHolidays.find(b => b.Days.find(x => +new Date(x.date) === +new Date(s.Day))))
            count -= employeeData.Business.HolidayEntitlement.TimeOffUnit === "Days" ? (s.isHalfDay ? .5 : 1) : (s.Hours ? s.Hours : 0);
          else
            if (employeeData.Business.CurrentEmployment.worksBankHolidays)
              count -= employeeData.Business.HolidayEntitlement.TimeOffUnit === "Days" ? (s.isHalfDay ? .5 : 1) : (s.Hours ? s.Hours : 0);
        })
      })
    return count;
  }

  return (<>
    <div className={general.Main}>
      <div className={styles.MainOuter}>
        <div className={styles.Main}>
          <div className={styles.Side}>
            {employees.map((data) => {
              return <Link className={RefID && parseInt(DecryptValue(RefID)) === data.department.ID ? styles.Active : ""} to={`/Employee/Portal/Departments/${EncryptValue(data.department.ID.toString())}`}>{data.department.Title}</Link>
            })}
          </div>
          <div className={styles.MainInner}>
            {loading ? <div className={general.Loading}><Loading /></div> : RefID ? employees.filter((empDep: any) => RefID && empDep.department.ID === parseInt(DecryptValue(RefID))).map((empDep: any) => {
              return <>
                <div className={styles.Header}>
                  <h1>
                    {empDep.department.Title} Information
                  </h1>
                  <button title='Department Tasks' onClick={() => setShow(empDep.department.ID)}>
                    <i>
                      <FontAwesomeIcon icon={faTasks} />
                    </i>
                  </button>
                  {User.HappinessOptionsData.length ? <button title='Department Happiness' onClick={() => setHappiness(empDep.department.ID)}>
                    <i>
                      <FontAwesomeIcon icon={faFaceGrinBeam} />
                    </i>
                  </button> : <></>}
                </div>

                <div className={styles.Department}>
                  <div className={styles.Updates}>
                    <div className={styles.UpdatesInner}>
                      {
                        GetUpdates(empDep).map((data: any) => {
                          return <>
                            <div className={`${styles.UpdatesItem} ${styles[data.Width]}`}>
                              <h1>{data.Title}</h1>
                              <div className={styles.UpdatesItemInner}>
                                {
                                  data.Data.length ? data.Data.map((dataBlock: any, ind: number) => {
                                    switch (data.Type) {
                                      case "LIEU":
                                        const emp: Employee = dataBlock;

                                        return <div className={styles.Stats}>
                                          <b>{emp.Personal.Forename} {emp.Personal.Surname} ({emp.Business.HolidayEntitlement.TimeOffUnit})</b>
                                          <div className={styles.StatsItem}>
                                            <small>Used</small>
                                            <b>{getUsedInLieuTime(emp, 1)}</b>
                                          </div>
                                          <div className={styles.StatsItem}>
                                            <small>Requested</small>
                                            <b>{getUsedInLieuTime(emp, 0)}</b>
                                          </div>
                                          <div className={styles.StatsItem}>
                                            <small>Total</small>
                                            <b>{getInLieuTime(emp)}</b>
                                          </div>
                                          <div className={styles.StatsItem}>
                                            <small>Available</small>
                                            <b>{getInLieuTime(emp) + getUsedInLieuTime(emp, 1)}</b>
                                          </div>
                                        </div>
                                      case "HOLIDAY":
                                        const empData: Employee = dataBlock;

                                        return <div className={styles.Stats}>
                                          <b>{empData.Personal.Forename} {empData.Personal.Surname} ({empData.Business.HolidayEntitlement.TimeOffUnit})</b>
                                          <div className={styles.StatsItem}>
                                            <small>Statutory</small>
                                            <b>{getTotalDays(empData) - getCarryOverDays(empData)}</b>
                                          </div>
                                          <div className={styles.StatsItem}>
                                            <small>Used</small>
                                            <b>{GetUsedCount(empData, 1)}</b>
                                          </div>
                                          <div className={styles.StatsItem}>
                                            <small>Requested</small>
                                            <b>{GetUsedCount(empData, 0)}</b>
                                          </div>
                                          <div className={styles.StatsItem}>
                                            <small>Carried Over</small>
                                            <b>{getCarryOverDays(empData)}</b>
                                          </div>
                                          <div className={styles.StatsItem}>
                                            <small>Total</small>
                                            <b>{getTotalDays(empData)}</b>
                                          </div>
                                          <div className={styles.StatsItem}>
                                            <small>Available</small>
                                            <b>
                                              {getTotalDays(empData) + GetUsedCount(empData, 1)}
                                            </b>
                                          </div>
                                        </div>
                                      case "TASKS":
                                        let task: Objective = dataBlock;
                                        let status: TaskStatus | undefined = User.TaskStatusOptionsData.find((t: TaskStatus) => t.Name === task.Status);
                                        let employee: Employee | undefined = Organisation.Employees.find((emp: Employee) => emp.ID === task.EmployeeID);

                                        return <div className={styles.Tasklist}>
                                          <b title={employee ? `${employee.Personal.Forename} ${employee.Personal.Surname}` : ""}>
                                            {employee ? `${employee.Personal.Forename[0]}${employee.Personal.Surname[0]}` : ""}
                                          </b>
                                          <div className={`${styles.TaskInner}`}>
                                            <p>
                                              {task.Title}
                                              <small>{task.Description}</small>
                                            </p>
                                          </div>
                                          <span style={{ backgroundColor: status?.Colour }}><i style={{ color: status?.Colour }}>{status?.Name}</i></span>
                                        </div>
                                      case "EMPLOYEES":
                                        return <div className={styles.Events}>
                                          <div className={`${styles.Event} ${styles.BIRTHDAY}`}>
                                            <h2>
                                              {dataBlock.Personal.Title}{dataBlock.Personal.Title ? ". " : ""}{dataBlock.Personal.Forename} {dataBlock.Personal.Surname}
                                              <b>
                                                <span>BIRTHDAY</span>
                                              </b>
                                            </h2>
                                            <p>{new Date(dataBlock.Personal.DateOfBirth).toLocaleDateString(window.navigator.language)}</p>
                                          </div>
                                        </div>
                                      case "EVENTS":
                                        return <div className={styles.Events}>
                                          <h2>
                                            <span>{dataBlock.Personal.Title}{dataBlock.Personal.Title ? ". " : ""}{dataBlock.Personal.Forename} {dataBlock.Personal.Surname}</span>
                                          </h2>

                                          {
                                            dataBlock.CalendarEvents.map((event: CalendarEvent) => {
                                              const colour: string | undefined = StatusColours.find((status: StatusColour) => status.Status === event.Type)?.Colour;

                                              return <Link to={`/Employee/Portal/Leave Requests/${EncryptValue(event.ID.toString())}`} style={{ borderLeftColor: colour }} className={`${styles.Event}`}>
                                                <h2 style={{ color: colour }}>
                                                  {event.Title ? event.Title : event.Type}
                                                  <b style={{ backgroundColor: colour }}>
                                                    <span>{EmploymentStatus[event.Type]}</span>
                                                  </b>
                                                </h2>
                                                <p>{new Date(event.StartDate).toLocaleDateString(window.navigator.language)} - {new Date(event.EndDate).toLocaleDateString(window.navigator.language)}{getTime(event)}</p>
                                              </Link>
                                            })
                                          }
                                        </div>
                                      case "CALENDAR":
                                        return <div className={styles.Events}>
                                          <h2>
                                            <span>{dataBlock.Personal.Title}{dataBlock.Personal.Title ? ". " : ""}{dataBlock.Personal.Forename} {dataBlock.Personal.Surname}</span>
                                          </h2>

                                          <PortalEmployeeScatterCalendar employee={dataBlock} setEmployee={() => { }} Month={new Date().getMonth() + 1} />
                                        </div>
                                      case "EMPLOYEES_BLOCK":
                                        return <EmployeeBlock Language={Language} setShowAddModal={() => { }} EmployeeData={dataBlock} User={User} Index={ind} isDismissed={false} />
                                    }
                                  }) : <div className={styles.Message}>
                                    <i>
                                      <FontAwesomeIcon icon={faQuestionCircle} />
                                    </i>
                                    <h3>{data.Error}</h3>
                                  </div>
                                }
                              </div>
                            </div>
                          </>
                        })
                      }
                    </div>
                  </div>
                </div>

                <div className={`${styles.Tasks} ${show === empDep.department.ID ? styles.Show : ""}`}>
                  <button className={styles.Back} onClick={() => setShow(0)}>
                    <i>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </i>
                    Back
                  </button>
                  <PortalTaskManager ID={empDep.department.ID} Type="Department" CustomerID={User.CustomerID ? EncryptValue(User.CustomerID.toString()) : ""} />
                </div>

                {User.HappinessOptionsData.length ? <div className={`${styles.Tasks} ${happiness === empDep.department.ID ? styles.Show : ""}`}>
                  <button className={styles.Back} onClick={() => setHappiness(0)}>
                    <i>
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </i>
                    Back
                  </button>
                  <PortalMoodMonitor ID={empDep.department.ID} Type="Department" CustomerID={User.CustomerID ? EncryptValue(User.CustomerID.toString()) : ""} />
                </div> : <></>}
              </>
            }) : <div className={styles.Unselected}>
              <h1>Select a Department to View</h1>
              <p>To view data related to a Department, you must first select a Department to view if you have multiple Departments within your Hierarchy</p>
              <i>
                <FontAwesomeIcon icon={faShare} />
              </i>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default EmployeePortalDepartment;
